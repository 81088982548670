import React from 'react'
import { Box, Typography } from "@mui/material"
import UploadFeedback from './UploadFeedback'
import { FAILED, UPLOADED } from '../../data/const'
import { sortByTimeStamp } from './../../utils/commonFunctions'


const DataDisplay = ({ data = [], actions = [], titleId }) => {
    const removeFiles = actions.find(action => action.actionType === 'remove')

    const handleFileDelete = (file_s3_url, file_name, file_id) => {
        if (removeFiles.callBackFunction) {
            removeFiles.callBackFunction(file_s3_url, file_name, file_id)
        }
    }

    return (
        <>
            {sortByTimeStamp(data, 'file_name')?.map((file, index) => {
                const { file_name, file_s3_url, file_status, file_id, upload_status, upload_status_message, error_message } = file

                return (
                    <Box
                        key={file_name}
                        style={{
                            backgroundColor: data?.length === 1 ? '#F8F8F8' : index % 2 !== 0 ? '#F8F8F8' : "transparent",
                            color: '#262626',
                            borderRadius: '8px',
                            marginRight: 0,
                            margin: index === 0 ? '0.75rem 0 0' : '0rem',
                            font: 'normal normal normal 16px/32px Helvetica Neue',
                            padding: '10px 24px',
                            boxSizing: 'border-box',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}>
                        <Typography
                            style={{
                                color: '#262626'
                            }}
                        >
                            {file_name}
                        </Typography>
                        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {
                                <UploadFeedback
                                    file={file}
                                    file_status={file_status}
                                    upload_status={upload_status}
                                    upload_status_message={upload_status_message}
                                    error_message={error_message}
                                />
                            }
                            {
                                removeFiles && (file_status === null || file_status === UPLOADED || file_status === FAILED) &&
                                <Typography
                                    onClick={() => handleFileDelete(file_s3_url, file_name, file_id)}
                                    style={{
                                        textDecoration: 'underline',
                                        cursor: 'pointer',
                                        color: '#3E3E3E'
                                    }}
                                >
                                    Remove
                                </Typography>
                            }
                        </Box>

                    </Box>
                )
            })}
        </>
    )
}

export default DataDisplay