import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import {
    Box,
    Table,
    Paper,
    TableContainer,
    TableBody,
    TableRow,
    NativeSelect,
    Pagination,
    PaginationItem,
    Typography,
    Link as MuiLink
} from '@mui/material';
import { Link } from "react-router-dom";
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ConfirmationDialog from "../dialog/ConfirmationDialog"
import { deleteClientById } from "./../../services/ClientService"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
        border: '1px solid transparent'
    },
    [`&.${tableCellClasses.body}`]: {
        color: theme.palette.primary.main
    }
}));

const StyledTableRow = styled(TableRow)(() => ({
    '&:nth-of-type(even)': {
        backgroundColor: '#F8F8F8',
    },
    'td:nth-of-type(1)': {
        borderRadius: '0.5rem 0 0 0.5rem',
        padding: '13px 20px'
    },
    'td:last-child': {
        borderRadius: '0 0.5rem 0.5rem 0',
    },
    root: {
        height: '44px',
        boxSizing: 'border-box',
        font: 'normal normal normal 16px/32px',

    }

}));

const StyledNativeSelect = styled(NativeSelect)(() => ({
    "&&&:before": {
        borderBottom: "none",
    },
    "&&&:after": {
        borderBottom: "none",
    }
}))


const StyledPaginationItem = styled(PaginationItem)({
    '&.Mui-selected': {
        background: 'transparent',
        fontWeight: '700',
        color: 'black',
    },
    '&.MuiPaginationItem-root': {
        padding: '0',
        margin: '0'
    },
    '.MuiPaginationItem-icon': {
        fontSize: '0.75rem',
    }
})


const ClientListTable = ({ searchText, clientListData, updatedClientListData }) => {
    const rowsPerPageOptions = [10, 15, 20]
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[1])
    const [currentPage, setCurrentPage] = useState(1)
    const [clientData, setClientData] = useState(clientListData)
    const [clientListToDisplay, setClientListToDisplay] = useState(clientData?.slice(0, rowsPerPage))
    const [openConfirmationDailog, setOpenConfirmationDailog] = useState(false)
    const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('')
    const [selectedClientId, setSelectedClientId] = useState(null)


    useEffect(() => {
        const filteredClientList = clientListData.filter(clientList => `${clientList.data.client_name}`.toLowerCase().includes(searchText.toLowerCase()))
        setClientData(filteredClientList)
        setCurrentPage(1)
        setClientListToDisplay(filteredClientList.slice(0, rowsPerPage))
    }, [searchText, rowsPerPage, clientListData])


    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage)
        const updatedDisplayData = clientData.slice((newPage - 1) * rowsPerPage, newPage * rowsPerPage);
        setClientListToDisplay(updatedDisplayData)
    }


    const handleChangeRowsPerPage = (event) => {
        const rowsPerPageUpdatedValue = event.target.value
        setRowsPerPage(parseInt(rowsPerPageUpdatedValue));
        setCurrentPage(1);
        const updatedDisplayData = clientData.slice(0, rowsPerPageUpdatedValue);
        setClientListToDisplay(updatedDisplayData)
    };


    const handleClientRemove = (client) => {
        setOpenConfirmationDailog(true)
        setConfirmationDialogTitle(`Are you sure you want to remove ${client.client_name} ?`)
        setSelectedClientId(client?.client_id)
    }


    const handleConfirmation = async (value) => {
        const clientListDataCopy = JSON.parse(JSON.stringify(clientListData))
        if (value) {

            const filteredClientList = clientListDataCopy?.filter(clientList => clientList.data.client_id !== selectedClientId)
            updatedClientListData(filteredClientList)
            setClientListToDisplay(filteredClientList.slice(0, rowsPerPage))

            const response = await deleteClientById(selectedClientId)
            if (response?.status !== 204) {
                updatedClientListData(clientListDataCopy)
                setClientListToDisplay(clientListDataCopy.slice(0, rowsPerPage))
                alert('client could not be deleted, try again later')
            }
        }
        setSelectedClientId(null)
    }

    return (
        <Box>
            <TableContainer component={Paper} elevation={0} style={{ marginTop: '19px' }} size="small">
                <Table >
                    <TableBody>
                        {clientListToDisplay.map((client, index) => {
                            const { client_name, client_id } = client.data;

                            return (
                                <StyledTableRow key={client_id} style={{ height: '44px !important' }}>
                                    <StyledTableCell scope="row">
                                        {client_name}
                                    </StyledTableCell>
                                    <StyledTableCell align="right" width="40px">
                                        <Link
                                            to={`/client-communications/${client_id}`}
                                            style={{ color: '#3E3E3E' }}
                                        >
                                            View
                                        </Link>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" width="40px">
                                        <MuiLink
                                            component="button"
                                            variant="body2"
                                            color='#3E3E3E'
                                            onClick={() => handleClientRemove(client.data)}
                                        >
                                            Remove
                                        </MuiLink>
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '2rem',
                alignItems: 'center'
            }}>
                <Box>
                    <Typography component="span" sx={{
                        color: '#262626',
                        opacity: '0.64',
                        marginRight: '0.75rem',
                        font: 'normal normal normal 16px/32px'
                    }}>
                        Rows per page:

                    </Typography>
                    <StyledNativeSelect
                        defaultValue={rowsPerPage}
                        variant="standard"
                        onChange={handleChangeRowsPerPage}
                        size="small"
                    >
                        {rowsPerPageOptions.map((option, index) => {
                            return <option key={index} value={option}>{option}</option>;
                        })}
                    </StyledNativeSelect>

                </Box>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth: '300px',
                    alignItems: 'center',
                    color: '#262626',
                    opacity: '0.64',
                }}>
                    <Typography component="span" style={{ marginRight: '1.5rem' }}>
                        {
                            `Results: 
                            ${clientData.length > 0 ? ((currentPage - 1) * rowsPerPage) + 1 : 0} - 
                            ${currentPage * rowsPerPage < clientData.length ? currentPage * rowsPerPage : clientData.length} 
                            of ${clientData.length}`}
                    </Typography>
                    <Pagination
                        component="div"
                        count={Math.ceil(clientData.length / rowsPerPage)}
                        page={currentPage}
                        onChange={handleChangePage}
                        size="small"
                        renderItem={(item) => (
                            <StyledPaginationItem
                                variant="string"
                                components={{
                                    previous: KeyboardDoubleArrowLeftIcon,
                                    next: KeyboardDoubleArrowRightIcon
                                }}
                                {...item}
                            />
                        )}
                    />
                </Box>
            </Box>
            {openConfirmationDailog && <ConfirmationDialog
                title={confirmationDialogTitle}
                open={openConfirmationDailog}
                setOpen={(value) => setOpenConfirmationDailog(value)}
                handleConfirmation={(value) => handleConfirmation(value)} />}
        </Box>
    );
}


export default ClientListTable