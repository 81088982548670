import { createTheme } from '@mui/material/styles';

const primaryLightColor = "#3E3E3E"
const primaryMainColor = "#262626"
const primaryDarkColor = "#000000"




const theme = createTheme({
    palette: {
        primary: {
            light: primaryLightColor,
            main: primaryMainColor,
            dark: primaryDarkColor
        }
    },
    typography: {
        fontFamily: [
            "Roboto",
            'Helvetica Neue',
        ].join(','),
        h1: {
            fontWeight: 700,
            fontSize: '2rem',
        },
        h2: {
            fontWeight: 700,
            fontSize: '1.5rem',
        },
        h3: {
            fontWeight: 400,
            fontSize: '1rem',
        },
        h4: {
            fontWeight: 400,
            fontSize: "2.125rem"
        },
        h5: {
            fontWeight: 400,
            fontSize: "1.5rem"
        },
        h6: {
            fontWeight: 700,
            fontSize: "1.25rem",
            lineHeight: "1.5rem"
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: "1.5rem"
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "0.875rem"
        },
        body1: {
            fontWeight: 400,
            fontSize: "1rem",
            letterSpacing: 0
        },
        body2: {
            fontWeight: 400,
            fontSize: "0.875rem"
        },
        button: {
            fontWeight: 500,
            fontSize: "0.875rem"
        },
        caption: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: "1rem"
        },
        overline: {
            fontWeight: 400,
            fontSize: "0.625rem",
            lineHeight: "1rem"
        }
    }
});

export default theme