import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getPreSignedUrl } from '../services/AwsBucketService'
import { getReportByReportId } from './../services/ClientService'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ViewReport = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { report_id } = params
    const [reportKey, setReportKey] = useState(null)
    const [reportPdfUrl, setReportPdfUrl] = useState(null)
    const [client_id, setClientId] = useState(null)


    useEffect(() => {
        (async () => {
            const fetchReportByReportId = await getReportByReportId(report_id)
            if (fetchReportByReportId) {
                const { client_id, report_key } = fetchReportByReportId?.data
                setReportKey(report_key)
                setClientId(client_id)
            }
        })()
    }, [report_id])


    useEffect(() => {
        if (reportKey) {
            (async () => {
                const downloadUrl = await getPreSignedUrl(reportKey, 'fetch')
                if (downloadUrl) {
                    setReportPdfUrl(downloadUrl)
                }
            })()
        }
    }, [reportKey])



    return (
        <div style={{ textAlign: 'center', marginBottom: '2rem' }}>
            {
                reportPdfUrl
                    ? <Box style={{ display: 'flex', flexDirection: 'column', margin: '1rem auto', width: '80%', alignItems: 'baseline' }}>
                        <Button variant="outlined" startIcon={<ArrowBackIcon />} style={{ marginBottom: '1rem', textTransform: 'none' }} onClick={() => navigate(`/report-data/${client_id}`)}>
                            Report Data
                        </Button>
                        <object data={reportPdfUrl}
                            width="100%"
                            height="800"
                        >
                        </object>
                    </Box>
                    : <h1 style={{ color: 'gray', marginTop: '25vh' }}>Fetching Report...</h1>
            }
        </div>
    )
}

export default ViewReport