import React from 'react'
import { Typography, Box } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import LinearProgress from '@mui/material/LinearProgress';
import { UPLOADING, UPLOADED, FAILED } from '../../data/const';
import CustomTooltip from '../customMuiComponent/CustomTooltip';

import { useState } from 'react';

const UploadFeedback = ({ file, file_status, upload_status, upload_status_message, error_message }) => {
    const { status_message } = file
    const [showFeedback, setShowFeedback] = useState(true)
    setTimeout(() => {
        file_status === UPLOADED && setShowFeedback(false)
    }, [5000])

    return (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
                showFeedback &&
                <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {file_status === UPLOADING
                        ? <LinearProgress style={{ width: '200px' }} /> : file_status === FAILED
                            ?

                            (error_message ? <CustomTooltip
                                title="Detail Error Message"
                                message={[error_message]}
                                placement="left-start"
                                style={{ color: 'red' }}
                            >
                                <ErrorOutlineIcon color='error' />
                            </CustomTooltip> : <ErrorOutlineIcon color='error' />)
                            : upload_status === 'success' && <CheckCircleOutlineIcon color='success' />}
                    <Typography
                        style={{
                            letterSpacing: '0px',
                            color: '#0B1E36',
                            fontWeight: 'bold',
                            marginLeft: '5px',
                        }}
                    >
                        {status_message}{(error_message && 'Invalid file') || upload_status_message}
                    </Typography>
                </Box>
            }
            <Typography
                style={{
                    color: '#3E3E3E',
                    marginLeft: '1.5rem'
                }}
            >
                {file_status === UPLOADING && "Uploading..."}
            </Typography>

        </Box>
    )
}

export default UploadFeedback