import React from 'react'
import { Grid, Typography, Box } from "@mui/material"
import CustomUploadButton from './button/CustomUploadButton'
import { getPreSignedUrl } from "./../services/AwsBucketService"
import axios from 'axios';
import { addTimeStamp, fileValidation } from "./../utils/commonFunctions"
import { updateInputDataAfterFileUpload } from "./../utils/updateClientData"
import { addClientInputFiles } from "./../services/ClientService"
import { UPLOADING, UPLOADED, SUCCESS, FAILED } from './../data/const'
import InfoIcon from '@mui/icons-material/Info';
import CustomTooltip from "./customMuiComponent/CustomTooltip"


const UploadFileToS3 = ({ clientInputData = [], setClientInputData, clientId, fileCopy, disabled, infoToolTip }) => {
  const { title_id, title, buttonText, acceptedFileTypes, parentFolder, validFileCondition = [] } = fileCopy


  const handleUploadClientData = async (file_name, title_id, file_status, file_s3_url, status_message) => {
    const clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData))
    const updatedClientInputData = updateInputDataAfterFileUpload(clientInputDataCopy, file_s3_url, file_name, title_id, file_status, status_message)

    if (file_status === UPLOADING) {
      setClientInputData(updatedClientInputData)
    }
    else if (file_status === SUCCESS) {
      const response = await addClientInputFiles(clientId, { file_s3_url })

      if (response?.file_status === UPLOADED) {
        let clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData))
        response.upload_status = SUCCESS
        response.upload_status_message = 'Upload successful'

        if (clientInputDataCopy?.[title_id]) {
          clientInputDataCopy[title_id] = [...clientInputDataCopy[title_id]?.filter(item => item.file_status !== FAILED), response]
        } else {
          clientInputDataCopy[title_id] = [response]
        }
        setClientInputData(clientInputDataCopy)
      } else {
        let clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData))
        response.upload_status = FAILED
        response.upload_status_message = response?.error_message || 'Invalid file'

        if (clientInputDataCopy?.[title_id]) {
          clientInputDataCopy[title_id] = [...clientInputDataCopy[title_id]?.filter(item => item.file_status !== FAILED), response]
        } else {
          clientInputDataCopy[title_id] = [response]
        }
        setClientInputData(clientInputDataCopy)
      }
    } else if (file_status === FAILED) {
      setClientInputData(updatedClientInputData)
    }
  }



  const handleFileUploadToS3 = async (file) => {
    let file_name = file.name
    file_name = addTimeStamp(file_name)
    const filePath = `${clientId}/input_data/${parentFolder}/${file_name}`

    // Display file in frontend without uploading into S3 bucket
    handleUploadClientData(file_name, title_id, UPLOADING, filePath)

    const { isValidFile, errorMessage } = fileValidation(file)
    if (!isValidFile) {
      handleUploadClientData(file_name, title_id, FAILED, filePath, errorMessage)
    } else {
      try {
        const preSignedUrl = await getPreSignedUrl(filePath, "upload")
        const config = {
          headers: {
            'Content-Type': file.type,
          }
        }
        const result = await axios.put(preSignedUrl, file, config)
        if (result.status === 200) {
          handleUploadClientData(file_name, title_id, SUCCESS, filePath, "Upload Successfull")
        }
      } catch (error) {
        handleUploadClientData(file_name, title_id, FAILED, filePath, 'Upload failed')
      }
    }
  }


  return (
    <Grid container justifyContent="space-between" style={{ position: 'relative' }}>
      <Typography variant="h2" >{title}</Typography>
      <Box style={{ display: 'flex' }}>
        {infoToolTip &&
          <CustomTooltip
            placement="left-start"
            title="Valid File Rule"
            message={validFileCondition}
          >
            <InfoIcon style={{ marginRight: '1rem', color: 'gray', marginTop: '0.4rem' }} />
          </CustomTooltip>
        }
        <CustomUploadButton
          buttonText={buttonText}
          onClickHandler={(files) => handleFileUploadToS3(files[0])}
          acceptedFileTypes={acceptedFileTypes}
          disabled={disabled}
        />
      </Box>
    </Grid>
  )
}

export default UploadFileToS3
