import axios from 'axios'

const baseUrl = process.env.REACT_APP_API_URL

const getPreSignedUrl = async (file_key, type) => {
    try {
        const config = {
            headers: {
                "Authorization": await localStorage.getItem('id_token'),
            },
            params: {
                file_key,
                type
            }
        }
        const response = await axios.get(`${baseUrl}/v1/fileurl`, config)
        return response?.data?.signed_url

    } catch (error) {
        console.log(error)
    }
}

export {
    getPreSignedUrl
}