import './App.css'
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Layout from './layout/Layout';
import Dashboard from "./pages/Dashboard";
import ClientCommunications from "./pages/ClientCommunications";
import ReportData from './pages/ReportData';
import Login from './pages/Login'
import PrivateRoute from './Auth/PrivateRoute';
import { useAuth } from './hooks/useAuth'
import { getAllClients } from './services/ClientService';
import ForgotPassword from './Auth/ForgotPassword';
import PublishReport from './pages/PublishReport';
import ViewReport from './pages/ViewReport';
import jwt_decode from "jwt-decode";
import UserPool from './Auth/UserPool';
import { Alert } from '@mui/material';

const App = () => {
  const pathName = useLocation()
  const { authDetails, handleLogoutSuccess } = useAuth()
  const [allClients, setAllClients] = useState(null)
  const [sessionExpired, setSessionExpired] = useState(false)

  useEffect(() => {
    if (authDetails?.isAuthenticated) {
      (async () => {
        const fetchAllClients = await getAllClients()
        setAllClients(fetchAllClients?.data)
      })()
    }

    window.scrollTo(0, 0)

    if (authDetails?.isAuthenticated) {
      const token_exp_at = jwt_decode(authDetails?.idToken)?.exp
      const current_time = new Date().getTime()
      if (token_exp_at * 1000 < current_time) {
        const cognitoUser = UserPool.getCurrentUser();
        cognitoUser.signOut();
        handleLogoutSuccess()
        setSessionExpired(true)
        setTimeout(() => {
          setSessionExpired(false)
        }, 4000)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName])

  return (
    <div className="App">
      {sessionExpired && <Alert
        severity="error"
        style={{ position: 'absolute', left: '40%', zIndex: '10', width: '20%', top: '10px', textAlign: 'center' }}>
        Session Expired!
      </Alert>}
      <Routes >
        <Route path="/" element={<Layout />}>
          <Route path="login" element={authDetails?.isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />} />
          <Route path="forgot-password" element={authDetails?.isAuthenticated ? <Navigate to="/dashboard" replace /> : <ForgotPassword />} />
          <Route index
            element={
              <PrivateRoute>
                <Navigate to="/dashboard" replace />
              </PrivateRoute>
            } />
          <Route path="dashboard"
            element={
              <PrivateRoute>
                {<Dashboard allClients={allClients} />}
              </PrivateRoute>
            } />
          <Route path={`client-communications/:client_id`}
            element={
              <PrivateRoute>
                <ClientCommunications allClients={allClients} />
              </PrivateRoute>
            } />
          <Route path={`report-data/:client_id`}
            element={
              <PrivateRoute>
                <ReportData allClients={allClients} />
              </PrivateRoute>
            } />
          <Route path={`publish-report/:report_id`}
            element={
              <PrivateRoute>
                <PublishReport />
              </PrivateRoute>
            } />
          <Route path={`view-report/:report_id`}
            element={
              <PrivateRoute>
                <ViewReport />
              </PrivateRoute>
            } />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "10rem", textAlign: "center" }}>
              <h1>Page Not Found!</h1>
            </main>
          }
        />
      </Routes>
    </div>
  )
}


export default App;
