import React from 'react'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { Typography } from "@mui/material"



// Custom tooltip
const CustomTooltip = styled(({ className, ...props }) => (

    < Tooltip {...props} classes={{ popper: className }}
        title={
            <React.Fragment>
                <Typography style={props?.style}>{props.title}</Typography>
                <ol>
                    {props?.message?.map((message, index) => {
                        return <li key={`${props?.message}_${index}`}>{message}</li>
                    })}
                </ol>
            </React.Fragment>
        } />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        fontWeight: '400',
        minWidth: 250,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


export default CustomTooltip 