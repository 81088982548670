import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'
import HorizontalDivider from '../components/layout-design/HorizontalDivider'
import SubMenu from '../components/layout-design/SubMenu'
import Footer from './../components/layout-design/Footer'
import { getClientById, deleteInputFileById } from "./../services/ClientService";
import UploadFileToS3 from "../components/UploadFileToS3"
import CommunicationCard from "./../components/client-communications/CommunicationCard"
import { updateInputDataAfterFileDelete } from '../utils/updateClientData'
import DataDisplay from '../components/data-display/DataDisplay'
import { uploadClientFilesCopy, communicationsDetailsData } from '../data/clientCommunicationCopy'
import { UPLOADING, FAILED } from '../data/const'



const ClientCommunications = ({ allClients }) => {
  const params = useParams()
  const [clientData, setClientData] = useState(null)
  const [clientInputData, setClientInputData] = useState([])
  const [clientName, setClientName] = useState('')
  const { client_id } = params;

  // updating client data when client id changes
  useEffect(() => {
    (async () => {
      const clientDetails = await getClientById(client_id);
      setClientData(clientDetails?.data)
      setClientName(clientDetails?.data?.client_name)
      const input_data = clientDetails?.data?.input_data || []
      setClientInputData(input_data)
    })();
  }, [client_id])



  const handleClientDataFileRemove = async (file_s3_url, file_name, file_id, title_id) => {
    const clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData))
    const updatedClientInputData = updateInputDataAfterFileDelete(clientInputDataCopy, file_s3_url, title_id)
    setClientInputData(updatedClientInputData)
    const response = await deleteInputFileById(file_id)
    if (response.status === 204) {
      console.log('File deleted successfully!')
    } else {
      setClientInputData(clientInputDataCopy)
      alert("Filed could not be deleted, try again later")
    }
  }

  return (
    <>
      <HorizontalDivider />
      <SubMenu client_id={client_id} client_data={clientData} allClients={allClients} updateClientName={(client_name) => setClientName(client_name)} />
      <Box style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        margin: '0 80px 2.75rem 80px',
        backgroundColor: 'white',
        boxShadow: '0px 3px 6px #00000029',
        padding: '40px',
        boxSizing: 'border-box',
      }}>
        {uploadClientFilesCopy.map((fileCopy, index) => {
          const { title_id } = fileCopy
          let data = clientInputData && clientInputData[title_id]
          const uploadInProgress = data?.filter(file => file.file_status === UPLOADING) || []
          if (uploadInProgress.length > 0) {
            data = data.filter(file => file.file_status !== FAILED)
          }
          return (
            <div key={index} >
              <UploadFileToS3
                clientData={clientData}
                setClientData={setClientData}
                clientInputData={clientInputData}
                setClientInputData={setClientInputData}
                clientId={client_id}
                fileCopy={fileCopy}
                disabled={data?.length === 10 || uploadInProgress.length > 0}
                infoToolTip={true}
              />
              <DataDisplay
                data={data}
                titleId={title_id}
                actions={
                  [
                    {
                      actionType: 'remove',
                      callBackFunction: (file_s3_url, file_name, file_id) => handleClientDataFileRemove(file_s3_url, file_name, file_id, title_id)
                    }
                  ]
                }
              />
              {!clientData && <Box style={{ textAlign: 'center', margin: '1rem 0' }}>
                <CircularProgress />
              </Box>
              }
            </div>
          )
        })}
      </Box>
      <Box style={{ marginBottom: '80px' }}>
        {
          communicationsDetailsData.map((communication, index) => {
            const { title_id, title, buttonText } = communication

            return <CommunicationCard
              key={index}
              title_id={title_id}
              title={title}
              buttonText={buttonText}
              files={clientInputData?.['contact_listings'] || []}
              childComponent={communication?.childComponent}
              clientData={clientData}
              clientName={clientName}
            />
          })
        }
      </Box>
      <Footer />
    </>
  )
}

export default ClientCommunications