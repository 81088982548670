const UPLOADING = 'uploading';
const UPLOADED = 'uploaded';
const SUCCESS = 'success';
const FAILED = 'failed';
const PROCESSING = 'processing';
const PROCESSED = 'processed'
const COMPLETED = 'completed'
const SUCCESSFULL = 'successful'
const NOT_INITIATED = 'not initiated'
const IN_PROCESSING = 'in processing'
const PROCESSING_SUCCESSFULL = "processing successful"
const MODEL_STATUS_MAP_OBJ = {
    "undefined": "Pre-processing",
    "not initiated": "Pre-processing",
    "in processing": "Processing",
    "processing successful": "Processed",
    "failed": "failed"
}



export {
    UPLOADING,
    UPLOADED,
    PROCESSING,
    PROCESSED,
    COMPLETED,
    SUCCESS,
    SUCCESSFULL,
    FAILED,
    NOT_INITIATED,
    IN_PROCESSING,
    PROCESSING_SUCCESSFULL,
    MODEL_STATUS_MAP_OBJ
}