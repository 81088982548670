import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, CircularProgress, Typography } from "@mui/material"
import { getReportsByClientId, deleteModelByReportId } from '../../services/ClientService'
import {
    FAILED, NOT_INITIATED, IN_PROCESSING, PROCESSING_SUCCESSFULL, COMPLETED, MODEL_STATUS_MAP_OBJ
} from "./../../data/const"
import ConfirmationDialog from '../dialog/ConfirmationDialog'
import { sortByTimeStamp } from './../../utils/commonFunctions'


const DisplayModels = ({ uniqueModels, setUniqueModels, clientReports, setClientReports, client_id, fetchingReport }) => {
    const navigate = useNavigate()
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [createReportConfirmation, setCreateReportConfirmation] = useState(false)
    const [currentReportId, setCurrentReportId] = useState(null)
    const [dialogDetails, setDialogDetails] = useState({ title: '', description: '' })
    const model_status_arr = [undefined, NOT_INITIATED, IN_PROCESSING, PROCESSING_SUCCESSFULL, FAILED]

    useEffect(() => {
        let updateRequired = false
        uniqueModels?.length > 0 && uniqueModels?.map((clientReport, index) => {
            let model_status = clientReport?.data?.model_status
            if (model_status === IN_PROCESSING || model_status === undefined) {
                updateRequired = true
            }
            return clientReport
        })
        if (updateRequired) {
            fetchModelUpdates()
        }
        // eslint-disable-next-line
    }, [uniqueModels])

    const fetchModelUpdates = () => setTimeout(() => {
        (async () => {
            const reports = await getReportsByClientId(client_id)
            if (reports?.length > 0) {
                // filtering uniqueModels by model_id
                const tempUniqueModels = []
                reports?.map(report => {
                    const alreadyPresent = tempUniqueModels.findIndex(model => model?.data?.model_id === report?.data?.model_id)
                    if (alreadyPresent === -1) {
                        tempUniqueModels.push(report)
                    }
                    return report
                })
                setUniqueModels(tempUniqueModels)
            }
        })()
    }, 120000)



    // check if report is already generated for this model
    const generatedReport = (report_id) => {
        const report = clientReports.find(clientReport => clientReport?.data?.report_id === report_id && clientReport?.data?.report_status === COMPLETED)
        return report
    }

    // create new report for a model
    const createNewReport = (clientReport, model_id, client_id, report_id) => {
        setCurrentReportId(report_id)
        if (generatedReport(report_id)) {
            const dialogDescription = `A report has already been created for this Model. If you create a new report then the old report will be replaced with new report.`
            setDialogDetails({ title: 'Are you sure you want to create new report?', description: dialogDescription })
            setCreateReportConfirmation(true)
        } else {
            navigate(`/publish-report/${report_id}`)
        }
    }


    // create report for a model which has already a report( The old report will be replaced)
    const handleReplaceReport = (value) => {
        setDialogDetails({ title: '', description: '' })
        if (value) {
            navigate(`/publish-report/${currentReportId}`)
        }
    }



    const handleModelDelete = (report_id) => {
        let dialogDescription = ''
        if (generatedReport(report_id)) {
            dialogDescription = `A report has already been created for this Model. If you delete the Model then the corresponding report will also be deleted permanently.`
        }
        setDialogDetails({ title: 'Are you sure you want to delete this model?', description: dialogDescription })
        setCurrentReportId(report_id)
        setDeleteConfirmation(true)
    }


    //  Delete model and report(if there is any) by report_id after delete confirmation.
    const handleDeleteConfirmation = async (value) => {
        setDialogDetails({ title: 'Are you sure you want to delete this model?', description: '' })
        if (value) {
            const updatedUniqueModels = uniqueModels.filter(uniqueModel => uniqueModel?.data?.report_id !== currentReportId)
            setUniqueModels(updatedUniqueModels)
            const updatedClientReports = clientReports.filter(clientReport => clientReport?.data?.report_id !== currentReportId && clientReport?.data?.report_status === COMPLETED)
            setClientReports(updatedClientReports)
            await deleteModelByReportId(currentReportId)
        }
        setCurrentReportId(null)
    }


    return (
        <Box
            style={{
                minHeight: '149px',
                display: 'flex',
                flexDirection: 'column',
                margin: '0 80px 5rem 80px',
                backgroundColor: 'white',
                boxShadow: '0px 3px 6px #00000029',
                padding: '40px 60px 40px 40px',
                boxSizing: 'border-box',
            }}>
            <Typography variant="h2">Models</Typography>

            {
                fetchingReport && <CircularProgress sx={{ margin: '1rem auto' }} />
            }
            {
                !fetchingReport &&
                <>
                    <Box>
                        {
                            uniqueModels?.length === 0 && <Typography style={{
                                font: 'normal normal normal 16px/32px',
                                letterSpacing: '0px',
                                color: '#262626',
                                opacity: 0.72,
                                marginTop: '12px'
                            }}>No Models</Typography>
                        }
                    </Box>
                    <Box>
                        {
                            uniqueModels?.length > 0 && sortByTimeStamp(uniqueModels, 'model_name')?.map((clientReport, index) => {
                                const { model_id, client_id, report_id, model_name } = clientReport?.data
                                const modelStatus = model_status_arr.find(status => status === clientReport?.data?.model_status)
                                const model_status_display_text = MODEL_STATUS_MAP_OBJ[modelStatus]
                                const activeCreateReportButton = modelStatus === PROCESSING_SUCCESSFULL
                                const activeDeleteButton = [PROCESSING_SUCCESSFULL, FAILED].includes(modelStatus)

                                return <Box
                                    key={`${model_id}-${index}`}
                                    style={{
                                        backgroundColor: uniqueModels?.length === 1 ? '#F8F8F8' : index % 2 !== 0 ? '#F8F8F8' : 'transparent',
                                        color: '#262626',
                                        borderRadius: '8px',
                                        marginRight: 0,
                                        font: 'normal normal normal 16px/32px',
                                        padding: '10px 24px',
                                        boxSizing: 'border-box',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}>
                                    <Box style={{ width: '40%' }}>
                                        <Typography
                                            style={{
                                                color: '#262626',
                                            }}
                                        >
                                            {model_name}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <Typography
                                            style={{
                                                width: '150px',
                                                color: '#3E3E3E',
                                                textAlign: 'left',
                                            }}
                                        >
                                            {model_status_display_text}
                                        </Typography>
                                    </Box>

                                    <Box style={{
                                        display: 'flex',
                                    }}>
                                        <Typography onClick={() => handleModelDelete(report_id)}
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                color: '#3E3E3E',
                                                pointerEvents: !activeDeleteButton && 'none',
                                                opacity: activeDeleteButton ? '1' : '0.4',
                                            }}
                                        >
                                            Delete
                                        </Typography>

                                        <Typography onClick={() => createNewReport(clientReport, model_id, client_id, report_id)}
                                            style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                color: '#3E3E3E',
                                                marginLeft: '2.5rem',
                                                pointerEvents: !activeCreateReportButton && 'none',
                                                opacity: activeCreateReportButton ? '1' : '0.4',
                                            }}
                                        >
                                            Create Report
                                        </Typography>
                                    </Box>
                                </Box>
                            })
                        }
                    </Box>
                </>
            }
            {/* confirmation model to create new report when this already a report */}
            {createReportConfirmation && <ConfirmationDialog
                description={dialogDetails?.description}
                title={dialogDetails?.title}
                open={createReportConfirmation}
                setOpen={(value) => setCreateReportConfirmation(value)}
                handleConfirmation={(value) => handleReplaceReport(value)} />}

            {/* confirmation model to delete the model */}
            {deleteConfirmation && <ConfirmationDialog
                description={dialogDetails?.description}
                title={dialogDetails?.title}
                open={deleteConfirmation}
                setOpen={(value) => setDeleteConfirmation(value)}
                handleConfirmation={(value) => handleDeleteConfirmation(value)} />}
        </Box>
    )
}

export default DisplayModels