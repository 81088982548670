import axios from 'axios'


const baseUrl = process.env.REACT_APP_API_URL
const config = {
    headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "Authorization": '',
    }
}

// Get all the clients Details
const getAllClients = async () => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const clients = await axios.get(`${baseUrl}/v1/clients`, config)
        return clients.data
    } catch (error) {
        console.error(error.response)
    }
}

// Get client by client id
const getClientById = async (client_id) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;

        const clients = await axios.get(`${baseUrl}/v1/clients/${client_id}`, config)
        return clients.data
    } catch (error) {
        console.error(error)
    }
}


// Create a new client
const createNewClient = async (client_name) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const data = {
            "data": {
                "client_name": client_name
            }
        }
        const client = await axios.post(`${baseUrl}/v1/clients`, data, config)
        return client.data
    } catch (error) {
        console.error(error)
    }
}


// Update a client details
const updateClientDetails = async (client_id, updatedClientData) => {
    let id_token = await localStorage.getItem('id_token');

    try {
        config.headers.Authorization = id_token;
        const data = {
            "data": updatedClientData
        }
        const updatedClientDetails = await axios.put(`${baseUrl}/v1/clients/${client_id}`, data, config)
        const response = {
            status: updatedClientDetails.status,
            data: updatedClientDetails.data
        }
        return response
    } catch (error) {
        const errorObj = {
            status: error?.response?.status,
            data: error?.response?.data
        }
        return errorObj
    }
}


// Add client input file
const addClientInputFiles = async (client_id, updatedClientData) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const data = {
            params: { "file_s3_url": updatedClientData?.file_s3_url }
        }
        const response = await axios.post(`${baseUrl}/v1/files`, null, { ...data, ...config })
        return response?.data?.data
    } catch (error) {
        console.log(error)
    }
}

// Delete an input file
const deleteInputFileById = async (file_id) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const response = await axios.delete(`${baseUrl}/v1/files/${file_id}`, config)
        return response
    } catch (error) {
        console.log(error)
    }
}


// Delete a client by its id
const deleteClientById = async (client_id) => {
    let id_token = await localStorage.getItem('id_token');

    try {
        config.headers.Authorization = id_token;
        const response = await axios.delete(`${baseUrl}/v1/clients/${client_id}`, config)
        return response
    } catch (error) {
        console.log(error)
    }
}


// get all reports by client_id
const getReportsByClientId = async (client_id) => {
    const id_token = await localStorage.getItem('id_token');
    try {
        const params = {
            client_id
        }
        config.headers.Authorization = id_token;
        const reports = await axios.get(`${baseUrl}/v1/reports`, { params, ...config })
        return reports?.data?.data
    } catch (error) {
        console.error(error)
    }
}


// run text Processing Model
const runTextProcessingModel = async (selectedFilesForModel, client_id) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const body = {
            data: {
                client_id: client_id,
                input_data: selectedFilesForModel
            }
        }
        const response = await axios.post(`${baseUrl}/v1/reports`, body, config)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}



// get report by report_id
const getReportByReportId = async (report_id) => {
    const id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const reports = await axios.get(`${baseUrl}/v1/reports/${report_id}`, config)
        return reports?.data
    } catch (error) {
        console.log(error)
    }
}

// update report by report id
const updateReportByReportId = async (report_id, updatedReport) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const data = {
            "data": updatedReport
        }
        const updatedReportByReportId = await axios.put(`${baseUrl}/v1/reports/${report_id}`, data, config)
        return updatedReportByReportId
    } catch (error) {
        console.log(error)
    }
}


// delete model by report id
const deleteModelByReportId = async (report_id) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const response = await axios.delete(`${baseUrl}/v1/reports/${report_id}`, config)
        return response
    } catch (error) {
        console.log(error)
    }
}

const sendEmail = async (data) => {
    let id_token = await localStorage.getItem('id_token');
    try {
        config.headers.Authorization = id_token;
        const response = await axios.post(`${baseUrl}/v1/email`, { data }, config)
        return response?.data
    } catch (error) {
        console.log(error)
    }
}

export {
    getAllClients,
    getClientById,
    createNewClient,
    updateClientDetails,
    addClientInputFiles,
    deleteClientById,
    deleteInputFileById,
    getReportsByClientId,
    runTextProcessingModel,
    getReportByReportId,
    updateReportByReportId,
    deleteModelByReportId,
    sendEmail
}