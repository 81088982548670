import React from 'react'
import { Grid, Typography } from '@mui/material'
import tivc_logo_5131_2x from "./../assets/images/tivc_logo_5131_2x.png"
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import UserPool from '../Auth/UserPool';
import { useAuth } from '../hooks/useAuth';

const TopBar = () => {
  const location = useLocation()
  const { authDetails, handleLogoutSuccess } = useAuth()

  const topNavOptions = [{
    title: 'Dashboard',
    pathName: '/dashboard'
  },
  {
    title: 'Log In',
    pathName: '/login'
  }
  ]

  const getComputedNavStyle = (navItem) => {
    const styleObj = {
      fontWeight: 'bold',
      fontSize: '1rem',
      margin: '0rem 2rem',
      color: '#0B1E36',
      padding: '0 0.5rem',
      cursor: 'pointer'
    }

    if (location.pathname === navItem.pathName) {
      return {
        ...styleObj,
        borderBottom: '4px solid #262626',
      }
    } else {
      return {
        ...styleObj,
        borderBottom: '4px solid transparent',
      }
    }
  }

  const handleLogout = () => {
    try {
      var cognitoUser = UserPool.getCurrentUser();
      cognitoUser.signOut();
      handleLogoutSuccess()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid container style={{
      padding: '16px 80px 32px 80px',
      justifyContent: 'left',
      alignItems: 'end'
    }}>
      <Grid item xs={2}>
        <img src={tivc_logo_5131_2x} alt="tivc_main_logo" style={{
          width: '184px',
          height: '58px',
          display: 'block'
        }} />
      </Grid>
      <Grid item xs={10}>
        <Grid container justifyContent="space-between">
          {topNavOptions.map((navItem, index) => {
            const { title, pathName } = navItem

            if (authDetails?.isAuthenticated && pathName === "/login") return null;

            return (
              <Grid item key={index} style={getComputedNavStyle(navItem)}>
                <Typography variant="subtitle1" >
                  <Link to={pathName} key={index} style={{ textDecoration: 'none', color: '#0B1E36', font: "normal normal bold 16px/32px Helvetica Neue" }}>{title}</Link>
                </Typography>
              </Grid>
            )
          })}
          {authDetails?.isAuthenticated && <Typography variant="subtitle1" >
            <span style={{ cursor: 'pointer', textDecoration: 'none', color: '#0B1E36', font: "normal normal bold 16px/32px Helvetica Neue" }} onClick={handleLogout}>Log Out</span>
          </Typography>}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TopBar