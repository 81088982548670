import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserPool from '../Auth/UserPool'
const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate()
    const [userSessions, setUserSessions] = useState({ isAuthenticated: false, idToken: null })
    const [authDetails, setAuthDetails] = useState(() => {
        const cognitoUser = UserPool.getCurrentUser();
        if (cognitoUser != null) {
            cognitoUser.getSession((err, session) => {
                if (err) {
                    return {
                        isAuthenticated: false,
                        idToken: null
                    }
                }
                if (session.isValid()) {
                    setUserSessions({
                        isAuthenticated: true,
                        idToken: session.idToken.jwtToken
                    })
                }
            })
        } else {
            return {
                isAuthenticated: false,
                idToken: null
            }
        }
    })


    useEffect(() => {
        setAuthDetails(userSessions)
    }, [userSessions])



    // handle navigation and manage idToken after succssfully logged in
    const handleLoginSuccess = async ({ idToken }) => {
        setAuthDetails({
            isAuthenticated: true,
            idToken: idToken
        })
        navigate('/dashboard')
    }

    // handle navigation after succssfully logged out
    const handleLogoutSuccess = () => {
        setAuthDetails({ isAuthenticated: false, idToken: null })
        navigate('/login')
    }

    const authUserDetails = useMemo(
        () => ({
            authDetails,
            handleLoginSuccess,
            handleLogoutSuccess
            // eslint-disable-next-line
        }), [authDetails]
    )

    return (
        <>
            {authUserDetails?.authDetails && <AuthContext.Provider value={authUserDetails}>{children}</AuthContext.Provider>}
        </>
    )
}

export const useAuth = () => {
    return useContext(AuthContext)
}
