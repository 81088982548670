import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/layout-design/Footer'
import { Box, Grid, TextField, Typography, useTheme, Paper } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info';
import CustomButton from '../components/button/CustomButton'
import UserPool from '../Auth/UserPool'
import { CognitoUser } from 'amazon-cognito-identity-js'
import HorizontalDivider from '../components/layout-design/HorizontalDivider'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { object, string } from 'yup'
import CustomTooltip from '../components/customMuiComponent/CustomTooltip';

// Email and password validation schema using yup
const emailValidation = object({
    email: string().email().required('Please enter a valid email address'),
});
const passwordValidation = object({
    newPassword: string()
        .required('Please Enter your password')
        .matches(
            // eslint-disable-next-line
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
            "Not a valid password, see the criteria on the right icon"
        )
})


const ForgotPassword = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [verificationCode, setVerificationCode] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')
    const [passwordUpdateError, setPasswordUpdateError] = useState({ isError: false, errorMessage: '' })
    const [inputError, setInputError] = useState({ email: '', newPassword: '', confirmNewPassword: '', verificationCode: '' })
    const [currentStep, setCurrentStep] = useState('send_verification_code')
    const [currentStepDetails, setCurrentStepDetails] = useState()

    useEffect(() => {
        if (passwordUpdateError?.isError === true) {
            setTimeout(() => {
                setPasswordUpdateError({ isError: false, errorMessage: '' })
            }, [5000])
        }
    }, [passwordUpdateError])


    useEffect(() => {
        const stepDetails = forgotPasswordSteps.find(step => step.step === currentStep)
        setCurrentStepDetails(stepDetails)
        // eslint-disable-next-line
    }, [currentStep])


    const forgotPasswordSteps = [
        {
            step: 'send_verification_code',
            title: 'Reset Password.',
            desc: 'Enter the email address associated with your account.',
            buttonText: 'Send Verification Code',
        },
        {
            step: 'create_password',
            title: 'Create New Password',
            desc: 'Enter the Verification Code we just sent you on your email address.',
            buttonText: 'Submit',
        },
        {
            step: 'password_reset_success',
            title: 'Your password has been reset successfully!',
            desc: 'Page will be redirected to the login page soon!',
            buttonText: '',
        }
    ]


    const handlePasswordChange = async (currentStepDetails) => {
        const userData = {
            Username: email.trim(),
            Pool: UserPool,
        };
        const cognitoUser = new CognitoUser(userData);

        // verify if the user exists in the pool then send verification code
        if (currentStep === 'send_verification_code') {
            try {
                await emailValidation.validate({ email })
                setInputError(inputError => ({ ...inputError, email: "" }))
                cognitoUser.forgotPassword({
                    onSuccess: (data) => {
                        setCurrentStep('create_password')
                    },
                    onFailure: function (err) {
                        setPasswordUpdateError({ isError: true, errorMessage: 'This email id does not exist' })
                    }
                })
            } catch (error) {
                setInputError(inputError => ({ ...inputError, email: "Please enter a valid email address" }))
            }
        }

        // verify code and create new password
        if (currentStep === 'create_password') {
            let passwordValidated = false;
            // verify if both the passwords are valid and same
            try {
                const validPassword = await passwordValidation.validate({ newPassword })
                if (validPassword && confirmNewPassword === newPassword) {
                    passwordValidated = true
                    setInputError(inputError => ({
                        ...inputError,
                        newPassword: '',
                        confirmNewPassword: ''
                    }))
                } else {
                    setInputError(inputError => ({
                        ...inputError,
                        newPassword: '',
                        confirmNewPassword: 'Password do not match'
                    }))
                }
            } catch (error) {
                setInputError(inputError => ({ ...inputError, newPassword: error.message }))
            }


            if (passwordValidated && verificationCode.trim().length === 0) {
                setInputError(inputError => ({
                    ...inputError,
                    newPassword: '',
                    confirmNewPassword: '',
                    verificationCode: 'Please enter verification code sent on your email id'
                }))
            } else if (passwordValidated && verificationCode.length > 0) {
                cognitoUser.confirmPassword(verificationCode, newPassword, {
                    onSuccess() {
                        setCurrentStep('password_reset_success')
                        setTimeout(() => {
                            navigate('/login')
                        }, [3000])
                    },
                    onFailure(err) {
                        setPasswordUpdateError({ isError: true, errorMessage: err.message })
                    },
                });
            }
        }
    }

    return (<>
        <HorizontalDivider />
        <Box style={{
            minHeight: '320px',
            width: '420px',
            margin: 'auto',
            marginTop: currentStep === 'create_password' ? '5vh' : '15vh',
            marginBottom: '10vh',
            backgroundColor: 'white',
            boxShadow: '0px 3px 6px #00000029',
            padding: '80px 40px 20px 40px',
            boxSizing: 'border-box',
            position: 'relative',
        }}>
            <Grid container>
                {/* show the error message */}
                {
                    passwordUpdateError?.isError && <Paper elevation={0} style={{ boxSizing: 'border-box', padding: '5px 10px', width: '330px', color: "#5F2120", backgroundColor: '#FCEDEC', position: 'absolute', top: 15 }}>
                        {
                            passwordUpdateError?.errorMessage
                        }
                    </Paper>
                }

                {
                    currentStep === 'password_reset_success' && <CheckCircleOutlineIcon color='success' style={{ fontSize: '4rem', margin: '-3rem auto 1rem auto' }} />
                }

                {/* form title and description for two steps two steps send_verification_code and create_password */}
                <Grid item>
                    <Typography variant="h2" color={theme.palette.primary.main} style={{ font: 'normal normal bold 24px/32px ' }}>
                        {currentStepDetails?.title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography variant="h4" color={theme.palette.primary.main} style={{ fontSize: '1rem', marginTop: '0.5rem' }}>
                        {currentStepDetails?.desc}
                    </Typography>
                </Grid>

                {/* input field for first step (send verification code) */}
                {
                    currentStep === 'send_verification_code' &&
                    <TextField
                        placeholder='Enter Email'
                        onChange={async (e) => {
                            setEmail(e.target.value)
                            try {
                                await emailValidation.validate({ email: e.target.value })
                                setInputError(inputError => ({ ...inputError, email: '' }))
                            } catch (error) {
                                setInputError(inputError => ({ ...inputError, email: 'Please enter a valid email address' }))
                            }
                        }}
                        value={email}
                        style={{
                            height: '3.5rem',
                            width: '20.5rem',
                            boxSizing: 'border-box',
                            marginTop: '0.75rem',
                            color: '#00000099',
                            textAlign: 'left',
                            font: 'normal normal normal 16px/24px Roboto',
                            letterSpacing: '0.15px'
                        }}
                        error={inputError?.email.trim().length > 0}
                        helperText={inputError?.email}
                    />
                }

                {/* input field for second step (create_password) */}
                {
                    currentStep === 'create_password' &&
                    <Grid item>
                        <Grid item style={{ position: 'relative' }}>
                            <TextField
                                placeholder='Verification Code'
                                onChange={(e) => {
                                    setVerificationCode(e.target.value)
                                    if (e.target.value.trim().length > 0) {
                                        setInputError(inputError => ({ ...inputError, verificationCode: '' }))
                                    } else {
                                        setInputError(inputError => ({ ...inputError, verificationCode: 'Please enter verification code sent on your email id' }))
                                    }
                                }}
                                value={verificationCode}
                                style={{
                                    height: '3.5rem',
                                    width: '20.5rem',
                                    boxSizing: 'border-box',
                                    marginTop: '1rem',
                                    color: '#00000099',
                                    textAlign: 'left',
                                    font: 'normal normal normal 16px/24px Roboto',
                                    letterSpacing: '0.15px'
                                }}
                                error={inputError?.verificationCode.trim().length > 0}
                                helperText={inputError?.verificationCode}
                            />
                        </Grid>
                        <TextField
                            placeholder='Enter New Password'
                            onChange={async (e) => {
                                setNewPassword(e.target.value)
                                try {
                                    await passwordValidation.validate({ newPassword: e.target.value })
                                    setInputError(inputError => ({ ...inputError, newPassword: '' }))
                                } catch (error) {
                                    setInputError(inputError => ({ ...inputError, newPassword: error.message }))
                                }
                            }}
                            value={newPassword}
                            type="password"
                            style={{
                                height: '3.5rem',
                                width: '20.5rem',
                                boxSizing: 'border-box',
                                marginTop: '2rem',
                                color: '#00000099',
                                textAlign: 'left',
                                font: 'normal normal normal 16px/24px Roboto',
                                letterSpacing: '0.15px'
                            }}
                            error={inputError?.newPassword.trim().length > 0}
                            helperText={inputError?.newPassword}
                        />
                        <CustomTooltip
                            title="Password Must Contains"
                            message={["One Uppercase", "One Lowercase", "One Number", "One Special Character", "8 Characters"]}
                            placement="left-start"
                        >
                            <InfoIcon style={{ position: 'absolute', right: 20, bottom: 210, color: 'gray' }} />

                        </CustomTooltip>
                        <TextField
                            placeholder='Re-enter New Password'
                            onChange={(e) => {
                                setConfirmNewPassword(e.target.value)
                                if (e.target.value === newPassword) {
                                    setInputError(inputError => ({ ...inputError, confirmNewPassword: '' }))
                                } else {
                                    setInputError(inputError => ({ ...inputError, confirmNewPassword: 'password do not match' }))
                                }
                            }}
                            value={confirmNewPassword}
                            type="password"
                            style={{
                                height: '3.5rem',
                                width: '20.5rem',
                                boxSizing: 'border-box',
                                marginTop: '2rem',
                                color: '#00000099',
                                textAlign: 'left',
                                font: 'normal normal normal 16px/24px Roboto',
                                letterSpacing: '0.15px'
                            }}
                            error={inputError?.confirmNewPassword.trim().length > 0}
                            helperText={inputError?.confirmNewPassword}
                        />
                    </Grid>
                }

                {/* buttons for two steps send_verification_code and create_password  with dynamic text */}
                {
                    currentStep !== 'password_reset_success' &&
                    <Grid item container style={{ marginTop: '2rem', width: '20.5rem', marginBottom: '1rem' }} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <CustomButton buttonText={currentStepDetails?.buttonText} onClickHandler={(currentStepDetails) => handlePasswordChange(currentStepDetails)} />
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Box>
        <Footer />
    </>)
}

export default ForgotPassword