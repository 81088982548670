import React, { useState, useEffect } from 'react'
import { Grid, Typography, TextField, Button } from '@mui/material'
import { NavLink } from 'react-router-dom';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { updateClientDetails } from './../../services/ClientService';
import { isValidName, isClientAlreadyExist } from './../../utils/commonFunctions'


const SubMenu = ({ client_id, client_data, allClients, updateClientName }) => {
    const [isNameEditing, setIsNameEditing] = useState(false)
    const [clientName, setClientName] = useState(client_data?.client_name)
    const [updatedName, setUpdatedName] = useState(client_data?.client_name)
    const [inputError, setInputError] = useState({ error: false, error_message: '' })


    useEffect(() => {
        setClientName(client_data?.client_name)
        setUpdatedName(client_data?.client_name)
    }, [client_data?.client_name])


    const navOptions = [{
        title: 'Client Communications',
        pathName: `/client-communications/${client_id}`
    }, {
        title: 'Report Data',
        pathName: `/report-data/${client_id}`
    }
    ]

    const getComputedNavStyle = (isActive) => {

        return {
            display: 'block',
            margin: "0 0.5rem",
            textDecoration: 'none',
            padding: '4px 16px',
            boxSizing: 'border-box',
            borderRadius: '8px',
            font: 'normal normal bold 16px/32px Helvetica Neue',
            letterSpacing: '0px',
            backgroundColor: isActive ? "#0B1E36" : "#E7EAE3",
            color: isActive ? '#FFFFFF' : "#262626",
        };
    }

    const handleNameChange = (e) => {
        setUpdatedName(e.target.value)
        if (!isValidName(e.target.value)) {
            setInputError({ error: true, error_message: 'Invalid name' });
        } else if (e.target.value.trim().length < 3 || e.target.value.trim().length > 40) {
            setInputError({ error: true, error_message: 'Name must contain between 3 and 40 characters' });
        }
        else {
            setInputError({ error: false, error_message: '' });
        }
    }


    const handleCancelEdit = () => {
        setIsNameEditing(false)
        setInputError({ error: false, error_message: '' });
        setUpdatedName(clientName)
    }



    const handleNameEdit = async () => {
        if (isClientAlreadyExist(allClients, updatedName)) {
            setInputError({ error: true, error_message: 'Client already exist' });
        }
        else {
            setIsNameEditing(false)
            setClientName(updatedName)
            const updatedClientDetails = {
                ...client_data,
                client_name: updatedName
            }
            updateClientName(updatedName)
            await updateClientDetails(client_id, updatedClientDetails)
            setInputError({ error: false, error_message: '' });
        }
    }


    return (
        <Grid container style={{
            padding: '32px 80px 32px 80px',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            marginBottom: '1rem'
        }}>
            <Grid sx={{ display: 'flex', height: '2.5rem' }}>
                {isNameEditing ?
                    (<>
                        <TextField
                            value={updatedName}
                            variant='standard'
                            error={inputError?.error}
                            helperText={inputError?.error_message}
                            onChange={(e) => handleNameChange(e)}
                        />
                        <Grid sx={{ display: 'flex' }}>
                            <Button onClick={() => handleNameEdit()} disabled={inputError?.error}>Save</Button>
                            <Button onClick={() => handleCancelEdit()}>Cancel</Button>
                        </Grid>
                    </>)
                    :
                    (<>
                        <Typography variant="h1" style={{ minWidth: '140px' }}>{clientName}</Typography>
                        <span style={{ marginLeft: '1rem', display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => setIsNameEditing(true)}>
                            <EditOutlinedIcon /> Edit client name
                        </span>
                    </>)
                }
            </Grid>
            <Grid sx={{ display: 'flex' }}>
                {navOptions.map((navItem, index) => {
                    const { title, pathName } = navItem
                    return (
                        <Grid key={index} justifyContent="space-between">
                            <NavLink
                                style={({ isActive }) => getComputedNavStyle(isActive)}
                                to={pathName}
                                key={index}
                            >
                                {title}
                            </NavLink>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}

export default SubMenu