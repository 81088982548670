import React, { useState } from 'react'
import { Box, TextareaAutosize, Typography, Paper, Alert } from "@mui/material"
import CustomButton from '../button/CustomButton'
import DataDisplayWithCheckbox from './../data-display/DataDisplayWithCheckbox'
import { UPLOADED } from '../../data/const'
import { useEffect } from 'react'
import { sendEmail } from './../../services/ClientService'


const CommunicationCard = ({ title_id, title, buttonText, onClickHandler, files, childComponent = null, fileInProgress, clientData, clientName }) => {
    const [message, setMessage] = useState('')
    const [calendlyLink, setCalendlyLink] = useState('')
    const [invalidMeetUrl, setInvalidMeetUrl] = useState({ error: false, error_message: '' })
    const [allSelectedFiles, setAllSelectedFiles] = useState([])
    const [sendButtonDisabled, setSendButtonDisabled] = useState(true)
    const [emailCount, setEmailCount] = useState(0)
    const [emailSending, setEmailSending] = useState(false)
    const [showEmailStatus, setShowEmailStatus] = useState({ send_status: null, status_message: null })
    const calendlyLinkRequired = ['interview', 'focus_group']

    useEffect(() => {
        let email_count = 0
        allSelectedFiles.forEach(row => {
            if (row?.isChecked) {
                email_count += row?.email_count
            }
        })

        if (email_count === 0 || email_count > 1000) {
            setSendButtonDisabled(true)
        } else if (childComponent !== null) {
            setSendButtonDisabled((childComponent !== null && invalidMeetUrl?.error) || calendlyLink?.trim().length === 0)
        } else {
            setSendButtonDisabled(false)
        }
        setEmailCount(email_count)
    }, [childComponent, calendlyLink, allSelectedFiles, invalidMeetUrl])

    const successfulUploaded = files?.filter(file => file?.file_status === UPLOADED)


    const handleSend = async () => {
        const email_files = Array.from(allSelectedFiles, file => file.file_s3_url)
        try {
            setEmailSending(true)
            setShowEmailStatus({ send_status: "info", status_message: "Invitation is under process, do not leave the page." })
            const reqBody = {
                email_files: email_files,
                email_type: title_id,
                custom_message: message,
                client_name: clientName,
            }
            if (calendlyLinkRequired.includes(title_id)) {
                reqBody.calendly_link = calendlyLink
            }
            const response = await sendEmail(reqBody)
            setShowEmailStatus({ send_status: 'success', status_message: response?.data?.message })
            setTimeout(() => setShowEmailStatus({ send_status: null, status_message: null }), 5000)
            setEmailSending(false)
        } catch (error) {
            setEmailSending(false)
            setShowEmailStatus({ send_status: 'error', status_message: `Opps! ${error?.message}. Please try again later.` })
            setTimeout(() => setShowEmailStatus({ send_status: null, status_message: null }), 5000)
            console.log(error)
        }
    }

    const handleTotalSelectedRows = (updatedRowsChecked, title_id) => {
        const selectedFiles = []
        updatedRowsChecked.forEach(rowDetails => {
            if (rowDetails.isChecked) {
                selectedFiles.push(rowDetails)
            }
        })
        setAllSelectedFiles(selectedFiles)
    }

    const handleMeetingLink = (e) => {
        const calendlyMeetingBaseUrl = "https://calendly.com/"
        if (e.target.value.trim().startsWith(calendlyMeetingBaseUrl)) {
            setCalendlyLink(e.target.value.trim())
            setInvalidMeetUrl({
                error: false,
                error_message: ''
            })
        } else {
            setInvalidMeetUrl({
                error: true,
                error_message: 'Invalid meeting URL'
            })
        }
    }


    return (
        <>
            <Box
                style={{
                    position: 'relative',
                    minHeight: '240px',
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 80px 2.75rem 80px',
                    backgroundColor: 'white',
                    boxShadow: '0px 3px 6px #00000029',
                    padding: '48px 40px',
                    boxSizing: 'border-box',
                    justifyContent: 'space-between',
                }}
            >
                <Box >
                    {showEmailStatus?.send_status &&
                        <Alert
                            severity={showEmailStatus?.send_status}
                            style={{
                                position: 'absolute',
                                zIndex: '10',
                                width: '60%',
                                left: "20%",
                                right: "20%",
                                top: '10px',
                                textAlign: 'center',
                                padding: "0 1rem"
                            }}
                        >
                            {showEmailStatus?.status_message}
                        </Alert>
                    }

                    <Typography variant="h2" style={{ marginBottom: '18px', }}>{title}</Typography>
                    {
                        files?.length > 0 && <DataDisplayWithCheckbox
                            data={{ files: successfulUploaded }}
                            rowSelectedStatus={handleTotalSelectedRows}
                            showEmailCount={true}
                        />
                    }
                    {emailCount > 1000 && <Alert
                        severity="warning"
                        style={{ position: 'absolute', zIndex: '10', width: '60%', left: "20%", right: "20%", top: '10px', textAlign: 'center', padding: "0 1rem" }}>
                        Selected emails count should not exceed 1000.
                    </Alert>}
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: '40px' }}>
                    <TextareaAutosize
                        placeholder="Write your message"
                        onChange={(e) => setMessage(e.target.value)}
                        style={{
                            width: '500px',
                            maxWidth: '80%',
                            boxSizing: 'border-box',
                            minHeight: '124px', marginBottom: '0.75rem',
                            font: 'normal normal normal 16px/24px Roboto',
                            letterSpacing: '0.15px',
                            color: '#00000099',
                            borderRadius: '4px',
                            outlined: "none",
                            opacity: 1,
                            padding: '1rem'
                        }}
                    />
                    <Box style={{ display: 'flex', alignItems: 'end', width: '500px', justifyContent: 'end' }}>
                        {childComponent && <Paper elevation={0} style={{
                            width: '260px', display: 'flex', marginRight: '1.5rem', height: '3rem'
                        }}>
                            {childComponent(handleMeetingLink, invalidMeetUrl)}
                        </Paper>}
                        <CustomButton
                            buttonText={buttonText}
                            onClickHandler={handleSend}
                            disabled={sendButtonDisabled || emailSending}
                            customButtonStyle={(sendButtonDisabled || emailSending) && { opacity: '0.8', background: 'gray' }}
                        />
                    </Box>
                </Box>
            </Box ></>
    )
}

export default CommunicationCard