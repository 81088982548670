import React from 'react'
import { Button, Typography } from '@mui/material'

const CustomUploadButton = ({ buttonText, customButtonStyle = {}, onClickHandler, disabled = false, acceptedFileTypes }) => {

    const allAcceptedFileTypes = acceptedFileTypes.map(fileType => fileType.toUpperCase());
    const handleFileChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            onClickHandler(e.target.files)
        }
        e.target.value = null;
    };

    return (
        <div>
            <Button
                component="label"
                variant="contained"
                disabled={disabled}
                style={{
                    padding: '8px 16px',
                    boxSizing: 'border-box',
                    font: 'normal normal medium 14px/16px Roboto',
                    background: '#0B1E36',
                    color: '#FFFFFF',
                    letterSpacing: '1.25px',
                    height: '35px',
                    borderRadius: '4px',
                    opacity: disabled ? '0.6' : '1',
                    cursor: disabled ? 'not-Allowed' : 'pointer',
                    ...customButtonStyle
                }}>
                {buttonText}
                <input
                    type="file"
                    onChange={(e) => handleFileChange(e)}
                    accept={acceptedFileTypes?.join(',')}
                    hidden
                />
            </Button>
            <Typography
                style={{
                    margin: '0 0 0 auto',
                    width: 'fit-content',
                    color: '#808080',
                    font: 'normal normal normal 12px / 32px '
                }}>Only {allAcceptedFileTypes?.join(', ')} Supported</Typography>
        </div>
    )
}

export default CustomUploadButton