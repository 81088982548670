import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import EditReport from '../components/publish-report/EditReport'
import GenerateReport from '../components/publish-report/GenerateReport'
import CreatePdf from './../components/publish-report/CreatePdf';
import { PDFViewer, BlobProvider } from '@react-pdf/renderer'
import { getPreSignedUrl } from './../services/AwsBucketService'
import { addTimeStamp } from './../utils/commonFunctions'
import { getReportByReportId, updateReportByReportId } from './../services/ClientService'
import axios from 'axios';
import { Button, Box } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



const PublishReport = () => {
    const params = useParams()
    const navigate = useNavigate()
    const { report_id } = params
    const [reportGenerated, setReportGenereted] = useState(false)
    const [modelResultsDataUrl, setModelResultsDataUrl] = useState([])
    const [automatedReport, setAutomatedReport] = useState([])
    const [showPdf, setShowPdf] = useState(false)
    const [editedReport, setEditedReport] = useState([])
    const [modelResultsPresigned, setModelResultsPresigned] = useState()
    // eslint-disable-next-line
    const [dataReady, setDataReady] = useState([])
    const [reportByReportId, setReportByReportId] = useState()
    const [reportName, setReportName] = useState('report')
    const [modelResults, setModelResults] = useState([])
    const [pdfLoading, setPdfLoading] = useState(true)


    // fetching report by report id
    useEffect(() => {
        (async () => {
            const fetchReportByReportId = await getReportByReportId(report_id)
            setReportByReportId(fetchReportByReportId)
        })()
    }, [report_id])



    // Add title (result name) for each model result
    useEffect(() => {
        let tempModelResultsTitle = []
        if (reportByReportId?.data?.model_results?.length > 0) {
            const { client_id, model_id } = reportByReportId?.data
            const urlPrefix = `${client_id}/model_data/${model_id}/model_results/`

            reportByReportId?.data?.model_results.map(model_result => {
                const title = model_result.replace(urlPrefix, '')
                tempModelResultsTitle.push({
                    title: title,
                    img: model_result
                })
                return model_result
            })
            setModelResults(tempModelResultsTitle)
        }
    }, [reportByReportId])



    // Get PresignedURl for for model results.
    useEffect(() => {
        if (modelResults?.length > 0) {
            Promise.all(modelResults.map(report => getPreSignedUrl(report.img, 'fetch')))
                .then(result => {
                    const tempPresignedUrl = []
                    modelResults.map((item, index) => {
                        tempPresignedUrl.push({ ...item, img: result[index] })
                        return item
                    })
                    setModelResultsPresigned(tempPresignedUrl)
                }
                ).catch((err) => {
                    console.log(err)
                })
        }
    }, [modelResults])



    // Converting model results image to DataURL by creating a canva image.
    useEffect(() => {
        if (modelResultsPresigned?.length > 0) {
            const modelResultsPresignedCopy = [...modelResultsPresigned]
            const sampledataURL = []
            modelResultsPresignedCopy.map(report => {
                const src = report.img
                toDataURL(src, (dataURL) => {
                    setDataReady(dataReady => ([...dataReady, { ...report, img: dataURL }]))
                    sampledataURL.push({ ...report, img: dataURL })
                    return { ...report, img: dataURL }
                })
                return report
            })
            setModelResultsDataUrl(sampledataURL)
        }
    }, [modelResultsPresigned])


    // convert image to DataUrl
    const toDataURL = (src, callback) => {
        var image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = function (dataURL) {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.height = this.naturalHeight;
            canvas.width = this.naturalWidth;
            context.drawImage(this, 0, 0);
            dataURL = canvas.toDataURL('image/png');
            callback(dataURL);
        };
        image.src = src;
        return
    }


    const generateAutomatedReport = (automatedReport) => {
        setAutomatedReport(automatedReport)
        setReportGenereted(true)
        window.scrollTo(0, 0)
    }


    const handleCreatePdf = (editedReport) => {
        setEditedReport(editedReport)
        setShowPdf(true)
    }


    // Upload pdf to s3 and then update report data in database
    const uploadReportToS3 = async (reportPdf) => {
        const { client_id, model_id } = reportByReportId?.data
        const report_name_with_timestamp = addTimeStamp(`${reportName.split(' ').join('_')}.pdf`)
        const editedReportCopy = JSON.parse(JSON.stringify(editedReport))
        const selectedReportDetails = []


        editedReportCopy.map(report => {
            const get_image_s3_url = modelResults.find(result => result.title === report.title)
            selectedReportDetails.push({
                file_s3_url: get_image_s3_url.img,
                description: report.description
            })
            return report
        })
        const filePath = `${client_id}/model_data/${model_id}/${report_name_with_timestamp}`
        const updatedReport = {
            ...reportByReportId?.data,
            report_key: filePath,
            report_status: 'completed',
            report_data: selectedReportDetails,
        }


        try {
            const preSignedUrl = await getPreSignedUrl(filePath, "upload")
            const config = {
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }
            const result = await axios.put(preSignedUrl, reportPdf, config)
            if (result.status === 200) {
                await updateReportByReportId(report_id, updatedReport)
                setPdfLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <div>



            {/* Report is not generated and there is no modelResultsDataUrl */}
            {
                !reportGenerated && modelResultsDataUrl?.length === 0 && <h1 style={{ margin: '100px auto', textAlign: 'center', color: 'grey' }}>
                    Fetching Model Results...
                </h1>
            }

            {/* Report is not generated but modelResultsDataUrl has atleast one dataUrl */}
            {
                !reportGenerated && modelResultsDataUrl?.length > 0 &&
                <GenerateReport
                    modelResultsDataUrl={modelResultsDataUrl}
                    generateAutomatedReport={generateAutomatedReport}
                />

            }

            {/* ModelResults are selected for add description and then generate PDF*/}
            {
                !showPdf && reportGenerated && <EditReport
                    automatedReport={automatedReport}
                    handleCreatePdf={handleCreatePdf}
                    handleReportName={(report_name) => setReportName(report_name)}
                />
            }

            {/* PDF report has been generated */}
            {
                showPdf &&
                (
                    <>
                        <Box style={{ width: "86vw", margin: '1rem auto' }}>
                            <Button
                                variant="outlined"
                                startIcon={<ArrowBackIcon />}
                                style={{ marginBottom: '1rem', textTransform: 'none' }}
                                disabled={pdfLoading}
                                onClick={() => navigate(`/report-data/${reportByReportId?.data?.client_id}`)}>
                                Report Data
                            </Button>
                        </Box>

                        {
                            pdfLoading ?
                                <p style={{ textAlign: 'center', color: 'red' }}>Report is getting Published. Do not navigate to other page</p> :
                                <p style={{ textAlign: 'center' }}>Report Published Successfully!!</p>
                        }

                        <PDFViewer style={{ width: "86vw", height: '80vh', margin: '1rem auto', display: 'flex' }}>
                            <CreatePdf editedReport={editedReport} />
                        </PDFViewer>

                        <BlobProvider document={CreatePdf({ editedReport })}>
                            {({ blob, url, loading, error }) => {
                                if (blob) {
                                    uploadReportToS3(blob)
                                }
                            }}
                        </BlobProvider>
                    </>
                )
            }
        </div>
    )
}

export default PublishReport