import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        backgroundColor: '#fff',
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 12,
        paddingBottom: 12,
        boxSizing: 'border-box'
    },
    description: {
        width: '100%',
        display: 'flex',
        fontSize: 16,
        marginTop: 8,
    },
    title: {
        fontSize: 20,
        marginBottom: 6,
        fontWeight: 'bold',
        textAlign: 'center'
    }
});


// Create Document Component
const CreatePdf = ({ editedReport }) => {
    return (
        < Document >
            {
                editedReport.map((report, index) => {
                    const { title, description, img } = report
                    const name_same_as_title = title.replace('.png', '').split('_').join(' ')

                    return (
                        <Page size="A4" style={styles.page} key={`${img}-${title}-${index}`}>
                            <View style={styles.title} >
                                <Text>{name_same_as_title}</Text>
                            </View>
                            <View style={styles.section} >
                                <Image src={img} style={{ width: "100%", height: "60vh" }} />
                                <Text style={styles.description} >{description}</Text>
                            </View>
                        </Page>
                    )
                })}
        </ Document >
    )
};

export default CreatePdf;