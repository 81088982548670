import { TextField } from "@mui/material"
const uploadClientFilesCopy = [
    {
        title_id: 'contact_listings',
        title: 'Contact Listings',
        buttonText: 'UPLOAD CSV FILE',
        acceptedFileTypes: [".csv"],
        parentFolder: 'contact_listings',
        validFileCondition: ["The file must contain 2 columns Name and Email.", "The first column should contain the name.", "The second column must contain an email address.", "There should not be any empty row.", "File should not contain more than 1000 emails."]
    }
]

const communicationsDetailsData = [
    {
        title_id: "initial_meeting",
        title: 'Initial Meeting',
        buttonText: 'SEND INITIAL MEETING INVITE',
    },
    {
        title_id: "communication",
        title: 'Communications',
        buttonText: 'SEND MESSAGE',

    },
    {
        title_id: "survey",
        title: 'Survey Invitations',
        buttonText: 'SEND SURVEY',
    },
    {
        title_id: "interview",
        title: 'Schedule an Interview',
        buttonText: 'SEND CALENDAR INVITE',
        childComponent: (handleChange, invalidMeetUrl) =>
            <TextField
                placeholder="Paste Calendly Link*"
                style={{ width: '100%', marginright: '1.25rem' }}
                onChange={(e) => handleChange(e)}
                error={invalidMeetUrl?.error}
                helperText={invalidMeetUrl?.error_message}
            />
    },
    {
        title_id: "focus_group",
        title: 'Focus Group',
        buttonText: 'SEND CALENDAR INVITE',
        childComponent: (handleChange, invalidMeetUrl) =>
            <TextField
                placeholder="Paste Calendly Link*"
                style={{ width: '100%', marginright: '1.25rem' }}
                onChange={(e) => handleChange(e)}
                error={invalidMeetUrl?.error}
                helperText={invalidMeetUrl?.error_message}
            />
    }
]

export {
    uploadClientFilesCopy,
    communicationsDetailsData
}