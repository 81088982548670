const uploadReportFilesData = [
    {
        title_id: 'surveys',
        title: 'Survey Data',
        buttonText: 'UPLOAD SURVEY DATA',
        acceptedFileTypes: [".xlsx"],
        parentFolder: 'surveys'
    },
    {
        title_id: 'focus_groups',
        title: 'Focus Group Data',
        buttonText: 'UPLOAD FOCUS GROUP DATA',
        acceptedFileTypes: [".docx"],
        parentFolder: 'focus_groups'
    },
    {
        title_id: 'policies',
        title: 'Policies & Procedures',
        buttonText: 'UPLOAD POLICES & PROCEDURES',
        acceptedFileTypes: [".docx", ".pdf"],
        parentFolder: 'policies'
    },
    {
        title_id: 'interviews',
        title: '1-on-1 Interviews',
        buttonText: 'UPLOAD 1-ON-1 INTERVIEWS',
        acceptedFileTypes: [".docx"],
        parentFolder: 'interviews'
    }
]


export {
    uploadReportFilesData,
}