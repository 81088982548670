import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Typography, CircularProgress } from "@mui/material"
import { updateReportByReportId } from "./../../services/ClientService"
import ConfirmationDialog from '../dialog/ConfirmationDialog'
import { PROCESSED } from '../../data/const'
import { sortByTimeStamp } from '../../utils/commonFunctions'

const DisplayCreatedReport = ({ clientReports, setClientReports, fetchingReport }) => {
    const navigate = useNavigate()
    const [deleteConfirmation, setDeleteConfirmation] = useState(false)
    const [currentReportId, setCurrentReportId] = useState(null)

    const navigateToReport = (report_id) => {
        navigate(`/view-report/${report_id}`)
    }

    const handleDeleteReport = (report_id) => {
        setCurrentReportId(report_id)
        setDeleteConfirmation(true)
    }

    const handleDeleteConfirmation = async (value) => {
        if (value) {
            const updatedClientReports = clientReports.filter(clientReport => clientReport?.data?.report_id !== currentReportId)
            setClientReports(updatedClientReports)
            const reportByReportId = clientReports.find(clientReport => clientReport?.data?.report_id === currentReportId)

            const updatedReport = {
                client_id: reportByReportId?.data?.client_id,
                report_status: PROCESSED,
            }
            await updateReportByReportId(currentReportId, updatedReport)
        }
    }

    return (
        <Box
            style={{
                minHeight: '149px',
                display: 'flex',
                flexDirection: 'column',
                margin: '0 80px 5rem 80px',
                backgroundColor: 'white',
                boxShadow: '0px 3px 6px #00000029',
                padding: '40px 60px 40px 40px',
                boxSizing: 'border-box',
            }}>
            <Typography variant="h2">Reports</Typography>
            {
                fetchingReport && <CircularProgress sx={{ margin: '1rem auto' }} />
            }
            {
                !fetchingReport &&
                <>
                    <Box>
                        {clientReports?.length === 0 && <Typography style={{
                            font: 'normal normal normal 16px/32px',
                            letterSpacing: '0px',
                            color: '#262626',
                            opacity: 0.72,
                            marginTop: '12px'
                        }}>No Created Reports</Typography>}
                    </Box>
                    <Box>
                        {clientReports?.length > 0 && sortByTimeStamp(clientReports, 'report_key')?.map((clientReport, index) => {
                            const { report_key, client_id, report_id, model_id, model_name } = clientReport?.data
                            const file_name_prefix = `${client_id}/model_data/${model_id}/`
                            const file_name = report_key.replace(file_name_prefix, '')

                            return <Box
                                key={`${report_key}-${index}`}
                                style={{
                                    backgroundColor: clientReports?.length === 1 ? '#F8F8F8' : index % 2 !== 0 ? '#F8F8F8' : 'transparent',
                                    color: '#262626',
                                    borderRadius: '8px',
                                    marginRight: 0,
                                    font: 'normal normal normal 16px/32px Helvetica Neue',
                                    padding: '10px 24px',
                                    boxSizing: 'border-box',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <Box>
                                    <Typography
                                        style={{
                                            color: '#262626',
                                            width: '30vw',
                                            marginRight: '1rem',
                                            overflowWrap: 'anywhere',
                                        }}
                                    >
                                        {file_name}
                                    </Typography>
                                </Box>
                                <Box>
                                    <Typography
                                        style={{
                                            color: 'black',
                                            fontWeight: '500'
                                        }}
                                    >
                                        {model_name}
                                    </Typography>
                                </Box>
                                <Box style={{
                                    display: 'flex',
                                }}>
                                    <Typography onClick={() => handleDeleteReport(report_id)}
                                        style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: '#3E3E3E'
                                        }}
                                    >
                                        Delete
                                    </Typography>

                                    <Typography onClick={() => navigateToReport(report_id)}
                                        style={{
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            color: '#3E3E3E',
                                            marginLeft: '2.5rem'
                                        }}
                                    >
                                        Go to Report
                                    </Typography>
                                </Box>
                            </Box>
                        })}
                    </Box>
                </>
            }

            {deleteConfirmation && <ConfirmationDialog
                title='Are you sure you want to delete the report?'
                open={deleteConfirmation}
                setOpen={(value) => setDeleteConfirmation(value)}
                handleConfirmation={(value) => handleDeleteConfirmation(value)} />}
        </Box>
    )
}

export default DisplayCreatedReport