import React, { useState } from 'react'
import Footer from '../components/layout-design/Footer'
import { Box, Grid, Link, TextField, Typography, useTheme, Paper } from '@mui/material'
import CustomButton from '../components/button/CustomButton'
import UserPool from '../Auth/UserPool'
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js'
import { useAuth } from './../hooks/useAuth'
import { useEffect } from 'react'
import HorizontalDivider from '../components/layout-design/HorizontalDivider'


const Login = () => {
    const theme = useTheme()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [loginError, setLoginError] = useState({ isError: false, errorMessage: '' })
    const [inputError, setInputError] = useState({ username: '', password: '', isError: false })
    const { handleLoginSuccess } = useAuth()

    useEffect(() => {
        if (loginError?.isError === true) {
            setTimeout(() => {
                setLoginError({ isError: false, errorMessage: '' })
            }, [4000])
        }
    }, [loginError])



    const handleSubmit = (e) => {
        e.preventDefault()
        let allInputsValid = true

        // validating input values
        if (username.trim().length === 0 && password.trim().length < 8) {
            allInputsValid = false
            setInputError({ username: 'Username is required', password: 'Password must have at least 8 characters', isError: true })
        } else if (username.trim().length === 0) {
            allInputsValid = false
            setInputError({ username: 'Username is required', password: '', isError: true })
        } else if (password.trim().length < 8) {
            allInputsValid = false
            setInputError({ username: '', password: 'Password must have at least 8 characters', isError: true })
        } else {
            setInputError({ username: '', password: '', isError: false })
        }


        if (allInputsValid) {
            const userData = {
                Username: username,
                Pool: UserPool,
            };
            const authDetails = new AuthenticationDetails({
                Username: username,
                Password: password
            })

            const cognitoUser = new CognitoUser(userData);
            cognitoUser.authenticateUser(authDetails, {
                onSuccess: function (result) {
                    const idToken = result.getIdToken().getJwtToken();
                    localStorage.setItem('id_token', idToken)
                    handleLoginSuccess({ idToken })
                },
                onFailure: function (err) {
                    setLoginError({ isError: true, errorMessage: 'Incorrect username or password' })
                },
            })
        }
    }


    return (<>
        <HorizontalDivider />
        <Box style={{
            height: '320px',
            width: '420px',
            margin: 'auto',
            marginTop: '15vh',
            backgroundColor: 'white',
            boxShadow: '0px 3px 6px #00000029',
            padding: '50px 40px 20px 40px',
            boxSizing: 'border-box',
            position: 'relative',
        }}>
            <Grid container>
                {
                    loginError?.isError && <Paper elevation={0} style={{ boxSizing: 'border-box', padding: '5px 10px', width: 'max-width', color: "#5F2120", backgroundColor: '#FCEDEC', position: 'absolute', top: 15 }}>{loginError?.errorMessage}</Paper>
                }
                <Grid item>
                    <Typography variant="h2" color={theme.palette.primary.main} style={{ font: 'normal normal bold 24px/32px' }}>
                        Log In
                    </Typography>
                </Grid>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <Grid item>
                        <TextField
                            placeholder='Username'
                            onChange={(e) => setUsername(e.target.value.trim())}
                            value={username}
                            style={{
                                height: '3.5rem',
                                width: '20.5rem',
                                boxSizing: 'border-box',
                                marginTop: '0.75rem',
                                color: '#00000099',
                                textAlign: 'left',
                                font: 'normal normal normal 16px/24px Roboto',
                                letterSpacing: '0.15px'
                            }}
                            error={inputError?.username.trim().length > 0}
                            helperText={inputError?.username}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            placeholder='Password'
                            onChange={(e) => setPassword(e.target.value.trim())}
                            value={password}
                            type="password"
                            style={{
                                height: '3.5rem',
                                width: '20.5rem',
                                boxSizing: 'border-box',
                                marginTop: '1.75rem',
                                color: '#00000099',
                                textAlign: 'left',
                                font: 'normal normal normal 16px/24px Roboto',
                                letterSpacing: '0.15px'
                            }}
                            error={inputError?.password.trim().length > 0}
                            helperText={inputError?.password}
                        />
                    </Grid>
                    <Grid item container style={{ marginTop: '2rem', width: '20.5rem' }} justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <CustomButton buttonText="Log in" buttonType="submit" />
                        </Grid>
                        <Grid item>
                            <Typography style={{ font: 'normal normal normal 14px/16px Roboto', textTransform: "uppercase" }}>
                                <Link href="forgot-password" >
                                    Forgot Password?
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Grid>
        </Box>
        <Footer />
    </>)
}

export default Login