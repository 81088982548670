import React, { useState } from 'react';
import { styled } from '@mui/system';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import CustomButton from './../button/CustomButton';
import { Typography, useTheme, Box } from '@mui/material';
import { isValidName, isClientAlreadyExist } from './../../utils/commonFunctions'
import CustomTooltip from './../customMuiComponent/CustomTooltip'
import InfoIcon from '@mui/icons-material/Info';




const CustomDialogBox = styled(Dialog)({
  '.MuiDialog-paperWidthSm': {
    margin: 0,
    width: '420px',
    height: '250px',
    padding: '45px 46px',
    boxSizing: 'border-box',
    borderRadius: '0',
    textAlign: 'left'
  },
  '.Mui-error': {
    marginLeft: '0'
  }
})


const AddClientDialog = ({ open, setOpen, addClient, allClientData }) => {
  const theme = useTheme()
  const [newClientName, setNewClientName] = useState('')
  const [inputError, setInputError] = useState({ error: false, error_message: '' })

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateClient = () => {
    if (isClientAlreadyExist(allClientData, newClientName)) {
      setInputError({ error: true, error_message: 'Client already exist' });
    } else {
      addClient(newClientName)
      setOpen(false)
      setInputError({ error: false, error_message: '' });
    }
  }


  const handleNameChange = (e) => {
    setNewClientName(e.target.value)
    if (!isValidName(e.target.value)) {
      setInputError({ error: true, error_message: 'Invalid name' });
    } else if (e.target.value.trim().length < 3 || e.target.value.trim().length > 40) {
      setInputError({ error: true, error_message: 'Name must contain between 3 and 40 characters' });
    } else {
      setInputError({ error: false, error_message: '' });
    }
  }


  return (
    <CustomDialogBox
      open={open}
      onClose={() => (_, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      disableEscapeKeyDown
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography color={theme.palette.primary.main} variant='h2' fontWeight='bold'>Add New Client</Typography>
        <CustomTooltip
          title="Valid Client Name Rule"
          message={["Must have at least 3 characters.", "Must have less than 40 characters.", "Only letters and periods (.) are allowed."]}
          placement="left-start"
        >
          <InfoIcon style={{ display: 'inline-block', marginLeft: '1rem', color: 'gray' }} />
        </CustomTooltip>
      </Box>
      <TextField
        margin="dense"
        placeholder='Client Name'
        type="text"
        error={inputError?.error}
        helperText={inputError?.error_message}
        variant="outlined"
        required
        sx={{ width: '328px' }}
        onChange={handleNameChange}
      />
      <Box sx={{
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 0 0.5rem 0'
      }}>
        <CustomButton
          onClickHandler={handleCreateClient}
          buttonText="Create Client"
          disabled={inputError?.error || newClientName === ''}
          customButtonStyle={(inputError?.error || newClientName === '') && { opacity: '0.7', background: 'gray' }}
        />
        <CustomButton
          onClickHandler={handleClose}
          buttonText="Cancel"
          buttonVariant="outlined"
          customButtonStyle={{
            background: 'white',
            color: 'black'
          }}
        />
      </Box>
    </CustomDialogBox>
  );
}


export default AddClientDialog