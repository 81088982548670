import { Box, Typography, useTheme } from '@mui/material'
import React from 'react'

const Footer = () => {
    const theme = useTheme()
    return (
        <footer
            style={{
                position: 'absolute',
                bottom: '0',
                width: '100%',
                boxSizing: 'border-box',
                color: theme.palette.primary.light,
                opacity: '0.64',
                padding: '0 80px',
                margin: '2.5rem auto 1.5rem auto',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body2">
                    Term & Conditions
                </Typography>
                <Typography variant="body2" style={{ marginLeft: '1rem' }}>
                    Privacy Policy
                </Typography>
            </Box>
            <Box>
                <Typography variant="body2">
                    TI Verbatim © 2022 | All Rights Reserved
                </Typography>
            </Box>
        </footer>
    )
}

export default Footer