import React, { useState, useEffect } from 'react'
import Footer from '../components/layout-design/Footer'
import { useNavigate } from 'react-router-dom'
import HorizontalDivider from '../components/layout-design/HorizontalDivider'
import { Grid, Box, Typography, useTheme, TextField, CircularProgress } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CustomButton from '../components/button/CustomButton'
import ClientListTable from '../components/dashboard/ClientListTable';
import AddClientDialog from "./../components/dashboard/AddClientDialog";
import { createNewClient } from './../services/ClientService'


const ClientListing = ({ allClients }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [searchClientText, setSearchClientText] = useState('')
  const [openAddClientDialog, setOpenAddClientDialog] = useState(false)
  const [allClientsData, setAllClientsData] = useState(null)

  useEffect(() => {
    setAllClientsData(allClients)
  }, [allClients])

  const handleSearchClient = (e) => {
    setSearchClientText(e.target.value)
  }

  const handleAddClient = () => {
    setOpenAddClientDialog(true)
  }

  const handleCreateClient = async (client_name) => {
    const response = await createNewClient(client_name)
    const client_id = response?.data?.client_id
    if (client_id) {
      navigate(`/client-communications/${client_id}`)
    } else {
      alert('Something went wrong creating the client. Please try again later.')
    }
  }

  return (
    <div>
      <HorizontalDivider />
      <Box style={{
        minHeight: '207px',
        margin: '47px 80px 80px 80px',
        backgroundColor: 'white',
        boxShadow: '0px 3px 6px #00000029',
        padding: '40px 40px 20px 40px',
        boxSizing: 'border-box',
      }}>
        <Grid container justifyContent="space-between">
          <Typography variant="h2" color={theme.palette.primary.main} style={{ font: 'normal normal bold 24px/32px' }}>
            Client List
          </Typography>
          <CustomButton buttonText="Add new client" onClickHandler={handleAddClient} />
        </Grid>
        <TextField
          placeholder='Search Client List'
          onChange={handleSearchClient}
          style={{
            height: '3.5rem',
            width: '20.5rem',
            boxSizing: 'border-box',
            marginTop: '0.75rem',
            color: '#00000099',
            textAlign: 'left',
            font: 'normal normal normal 16px/24px Roboto',
            letterSpacing: '0.15px'
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            )
          }}
        />
        {allClientsData && <ClientListTable
          searchText={searchClientText}
          clientListData={allClientsData}
          updatedClientListData={(updatedClientListData) => setAllClientsData(updatedClientListData)}
        />}
        {
          !allClientsData && <Box style={{ textAlign: 'center', margin: '2rem 0' }}>
            <CircularProgress />
          </Box>
        }
      </Box>
      {openAddClientDialog &&
        <AddClientDialog
          open={openAddClientDialog}
          setOpen={setOpenAddClientDialog}
          addClient={(clientName) => handleCreateClient(clientName)}
          allClientData={allClientsData}
        />}
      <Footer />
    </div>
  )
}

export default ClientListing