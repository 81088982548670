import React, { useState } from 'react'
import { Box, TextField, Typography } from '@mui/material';
import ImageListItem from '@mui/material/ImageListItem';
import CustomButton from '../button/CustomButton';
import EditReportName from './EditReportName'



const EditReport = ({ automatedReport, handleCreatePdf, handleReportName }) => {
    const [editedReport, setEditedReport] = useState(
        automatedReport.map(report => {
            report.description = ''
            return report
        })
    )

    const handleDescriptionUpdate = (e, reportTitle) => {
        const updatedReport = editedReport.map(report => {
            if (report.title === reportTitle) {
                return {
                    ...report, description: e.target.value,
                }
            }
            return report
        })
        setEditedReport(updatedReport)
    }


    const publishReport = () => {
        handleCreatePdf(editedReport)
    }

    return (
        <Box
            style={{ margin: '47px 189px 80px 189px' }}
        >
            <EditReportName handleReportName={handleReportName} />
            <Typography>
                Edit your generated report
            </Typography>
            {editedReport?.map(report => {
                return (
                    <ImageListItem key={report.img} style={{ margin: '4.5rem 0rem', width: '100%', background: 'white', padding: '1rem' }}>
                        <img
                            src={report.img}
                            alt={report.title}
                            style={{
                                borderRadius: '8px',
                                boxSizing: 'border-box',
                                objectFit: 'contain'
                            }}
                        />
                        <TextField
                            id="outlined-multiline-flexible"
                            placeholder='Enter Description of Above Image '
                            multiline
                            rows={4}
                            value={report.description}
                            onChange={(e) => handleDescriptionUpdate(e, report.title)}
                            margin="normal"
                            sx={{ backgroundColor: '#f5f9fa' }}
                            fullWidth
                        />
                    </ImageListItem>
                )
            })}

            <CustomButton
                buttonText="CONFIRM & PUBLISH"
                onClickHandler={() => publishReport(automatedReport)}
                disabled={automatedReport?.length > 0 ? false : true}
                customButtonStyle={{
                    marginLeft: 'auto',
                    display: 'flex',
                    backgroundColor: automatedReport?.length > 0 ? "#0B1E36" : "#FFFFFF61",
                    color: automatedReport?.length > 0 ? '#FFFFFF' : '#00000061'
                }}
            />
        </Box >
    )
}

export default EditReport