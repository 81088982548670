import React, { useState } from 'react'
import CustomButton from './../button/CustomButton'
import Checkbox from '@mui/material/Checkbox';
import { Box } from '@mui/material';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import ImageListItemBar from '@mui/material/ImageListItemBar';


const GenerateReport = ({ modelResultsDataUrl, generateAutomatedReport }) => {
    const [selectedModelResults, setSelectedModelResults] = useState([])

    const handleSelectedReport = (e, title) => {
        if (e.target.checked === true) {
            const selectedReport = modelResultsDataUrl.find(report => report.title === title)
            setSelectedModelResults(selectedModelResults => ([...selectedModelResults, selectedReport]))
        } else if (e.target.checked === false) {
            setSelectedModelResults(selectedModelResults => selectedModelResults.filter(report => report.title !== title))
        }
    }

    return (
        <Box style={{
            margin: '47px 80px 80px 80px',
        }}>
            <ImageList style={{ justifyContent: 'space-between', display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box' }}>
                {modelResultsDataUrl?.map((item, index) => {
                    const isSelected = selectedModelResults.some(report => report.title === item.title)
                    const name_same_as_title = item.title.replace('.png', '').replace('_', ' ')

                    return (
                        <ImageListItem key={Math.random()} style={{ margin: '2rem 0rem', width: '48%', background: 'white' }}>
                            <ImageListItemBar
                                actionIcon={
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isSelected}
                                                onChange={(e) => handleSelectedReport(e, item.title)}
                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                                style={{ marginLeft: '4px', }}
                                            />
                                        }
                                        sx={{ '.MuiFormControlLabel-label': { wordBreak: 'break-all' } }}
                                        label={name_same_as_title}
                                    />
                                }
                                style={{ paddingLeft: '4px', backgroundColor: '#FBFBF1' }}
                                actionPosition={'left'}
                                position="below"
                            />
                            <img
                                src={item.img}
                                alt={item.title}
                                style={{
                                    objectFit: 'contain',
                                    border: `4px solid ${isSelected ? '#0B1E36' : '#FBFBF1'}`,
                                    borderRadius: '8px',
                                    height: '367px',
                                    boxSizing: 'border-box',
                                }}
                                loading="lazy"
                            />
                        </ImageListItem>
                    )
                })}
            </ImageList>
            <CustomButton
                buttonText="GENERATE AUTOMATED REPORT"
                onClickHandler={() => generateAutomatedReport(selectedModelResults)}
                disabled={selectedModelResults?.length > 0 ? false : true}
                customButtonStyle={{
                    marginLeft: 'auto',
                    display: 'flex',
                    backgroundColor: selectedModelResults?.length > 0 ? "#0B1E36" : "#FFFFFF61",
                    color: selectedModelResults?.length > 0 ? '#FFFFFF' : '#00000061'
                }}
            />
        </Box>
    )
}

export default GenerateReport