import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom'
import HorizontalDivider from '../components/layout-design/HorizontalDivider'
import SubMenu from '../components/layout-design/SubMenu';
import { Box, Typography } from "@mui/material"
import { getClientById, deleteInputFileById, getReportsByClientId, runTextProcessingModel } from "./../services/ClientService"
import UploadFileToS3 from '../components/UploadFileToS3'
import CustomButton from '../components/button/CustomButton'
import DisplayCreatedReport from '../components/report-data/DisplayCreatedReport';
import DisplayModels from '../components/report-data/DisplayModels';
import DataDisplayWithCheckbox from '../components/data-display/DataDisplayWithCheckbox';
import { updateInputDataAfterFileDelete } from "./../utils/updateClientData";
import { uploadReportFilesData } from "./../data/reportDataCopy"
import Footer from '../components/layout-design/Footer';
import CircularProgress from '@mui/material/CircularProgress';
import { UPLOADING, FAILED, IN_PROCESSING } from '../data/const';

const response_type_file = ["surveys", "focus_groups", "interviews"]


const ReportData = ({ allClients }) => {
  const params = useParams()
  const { client_id } = params
  const [clientData, setClientData] = useState(null)
  const [clientInputData, setClientInputData] = useState([])
  const [textProcessingReady, setTextProcessingReady] = useState(false)
  const [allSelectedRows, setAllSelectedRows] = useState(null)
  const [modelProcessing, setModelProcessing] = useState(textProcessingReady)
  const [clientReports, setClientReports] = useState([])
  const [uniqueModels, setUniqueModels] = useState([])
  const [fetchingReport, setFetchingReports] = useState(true)
  const [selectedFilesForModel, setSelectedFilesForModel] = useState({
    surveys: [],
    focus_groups: [],
    policies: [],
    interviews: []
  })



  useEffect(() => {
    (async () => {
      const clientDetails = await getClientById(client_id);
      setClientData(clientDetails?.data || [])
      const input_data = clientDetails?.data?.input_data || []
      setClientInputData(input_data)


      //Get all reports by client id
      const reports = await getReportsByClientId(client_id)
      const publishedReport = reports?.filter(report => report?.data?.report_key)
      setClientReports(publishedReport)


      // filtering uniqueModels
      const uniqueModelsArr = []
      reports?.map(report => {
        const alreadyPresent = uniqueModelsArr.findIndex(model => model?.data?.model_id === report?.data?.model_id)
        if (alreadyPresent === -1) {
          uniqueModelsArr.push(report)
        }
        return report
      })
      setUniqueModels(uniqueModelsArr)
      if (uniqueModels) {
        setFetchingReports(false)
      }

    })();
    setTextProcessingReady(textProcessingReady => textProcessingReady)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client_id])



  useEffect(() => {
    const modelInProcessing = uniqueModels?.filter(model => model?.data?.model_status === IN_PROCESSING || model?.data?.model_status === undefined)
    setModelProcessing(modelInProcessing.length > 0)
  }, [uniqueModels])


  useEffect(() => {
    const initialSelectedRows = []
    uploadReportFilesData?.forEach(fileSection => {
      initialSelectedRows.push({
        title_id: fileSection.title_id,
        selectedRowCount: 0
      })
    })
    setAllSelectedRows(initialSelectedRows)
    // eslint-disable-next-line
  }, [uploadReportFilesData])


  // Delete Input file by file_id
  const handleReportDataFileRemove = async (file_s3_url, file_name, file_id, title_id) => {
    const clientInputDataCopy = JSON.parse(JSON.stringify(clientInputData))
    const updatedClientInputData = updateInputDataAfterFileDelete(clientInputDataCopy, file_s3_url, title_id)
    setClientInputData(updatedClientInputData)

    const response = await deleteInputFileById(file_id)
    if (response) {
      console.log('File deleted successfully!')
    } else {
      setClientInputData(clientInputDataCopy)
      alert("Filed could not be deleted, try again later")
    }
  }


  const handleTotalSelectedRows = (updatedRowsChecked, title_id) => {
    let response_type_file_count = 0
    const updatedSelectedRows = allSelectedRows
    const selectedRows = updatedRowsChecked.filter(rowDetails => rowDetails.isChecked)

    setSelectedFilesForModel(selectedFilesForModel => ({ ...selectedFilesForModel, [title_id]: selectedRows }))

    updatedSelectedRows.map((group) => {
      if (group.title_id === title_id) {
        group.selectedRowCount = selectedRows.length
      }
      return group
    })
    setAllSelectedRows(updatedSelectedRows)


    // Total selected response_type_file 
    response_type_file.map(response_file => {
      const response_selected_file_count = updatedSelectedRows?.find(file => file.title_id === response_file)?.selectedRowCount
      response_type_file_count += response_selected_file_count
      return response_file
    })


    if (response_type_file_count > 0) {
      setTextProcessingReady(true)
    } else {
      setTextProcessingReady(false)
    }
  }



  // call api to run text processing model
  const handleTextProcessing = async () => {
    setModelProcessing(true)
    const newModel = await runTextProcessingModel(selectedFilesForModel, client_id)
    if (newModel) {
      setUniqueModels(uniqueModels => ([...uniqueModels, newModel]))
    }
  }

  return (
    <div>
      <HorizontalDivider />
      <SubMenu client_id={client_id} client_data={clientData} allClients={allClients} />
      <Box style={{
        minHeight: '467px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        margin: '0 80px 2.75rem 80px',
        backgroundColor: 'white',
        boxShadow: '0px 3px 6px #00000029',
        padding: '40px 60px 40px 40px',
        boxSizing: 'border-box',
      }}>
        {uploadReportFilesData?.map((fileCopy, index) => {
          const { title_id } = fileCopy;
          let data = clientInputData && clientInputData[title_id]
          const uploadInProgress = data?.filter(file => file.file_status === UPLOADING) || []

          if (uploadInProgress.length > 0) {
            data = data.filter(file => file.file_status !== FAILED)
          }

          const modelProcessingStyle = modelProcessing ? {
            pointerEvents: 'none',
            opacity: '0.4'
          } : {}

          return <div key={index} style={{ marginTop: '2.75rem', ...modelProcessingStyle }}>
            <UploadFileToS3
              clientData={clientData}
              setClientData={setClientData}
              clientInputData={clientInputData}
              setClientInputData={setClientInputData}
              clientId={client_id}
              fileCopy={fileCopy}
              disabled={uploadInProgress.length > 0 || fetchingReport}
            />
            {fetchingReport && <CircularProgress style={{ margin: ' 1rem 50%' }} disableShrink />}
            {
              clientData && !fetchingReport && <DataDisplayWithCheckbox
                data={{ files: data }}
                titleId={title_id}
                rowSelectedStatus={handleTotalSelectedRows}
                actions={[{
                  actionType: 'remove',
                  callBackFunction: (file_s3_url, file_name, file_id) => handleReportDataFileRemove(file_s3_url, file_name, file_id, title_id)
                }]}
              />
            }
          </div>
        })}
        <Box style={{ display: 'flex', marginLeft: 'auto', marginTop: '1.5rem' }}>
          {modelProcessing && <CircularProgress color="inherit" />}
          <CustomButton
            buttonText={modelProcessing ? "RUNNING TEXT PROCESSING MODEL" : "RUN TEXT PROCESSING MODEL"}
            customButtonStyle={{
              width: 'fit-content',
              marginLeft: 'auto',
              height: '',
              backgroundColor: textProcessingReady && !modelProcessing ? "#0B1E36" : "#FFFFFF61",
              color: textProcessingReady && !modelProcessing ? '#FFFFFF' : '#00000061'
            }}
            disabled={!textProcessingReady || modelProcessing}
            onClickHandler={handleTextProcessing}
          />
        </Box>
        <Box>
          {!modelProcessing && <Typography
            variant="caption"
            style={{
              marginLeft: 'auto',
              display: 'flex',
              color: "#808080",
              width: 'fit-content',
            }}>
            Must select a Response type to run model.
          </Typography>}
        </Box>
      </Box>
      <DisplayModels
        uniqueModels={uniqueModels}
        setUniqueModels={setUniqueModels}
        clientReports={clientReports}
        setClientReports={setClientReports}
        client_id={client_id} fetchingReport={fetchingReport}
      />
      <DisplayCreatedReport
        clientReports={clientReports}
        setClientReports={setClientReports}
        fetchingReport={fetchingReport}
      />
      <Footer />
    </div>
  )
}

export default ReportData