import React, { useState } from 'react'
import { Grid, Typography, TextField, Button } from '@mui/material'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

const EditReportName = ({ handleReportName }) => {
    const [isNameEditing, setIsNameEditing] = useState(false)
    const [reportName, setReportName] = useState('report')
    const [updatedReportName, setUpdatedReportName] = useState('report')


    const handleCancelEdit = () => {
        setUpdatedReportName(reportName)
        setIsNameEditing(false)
    }

    const handleNameEdit = async () => {
        setIsNameEditing(false)
        setReportName(updatedReportName)
        handleReportName(updatedReportName)
    }


    return (
        <Grid container style={{
            alignItems: 'center',
            display: 'flex',
            height: '40px',
            marginBottom: '2rem'
        }}>
            {isNameEditing ?
                (<>
                    <TextField
                        value={updatedReportName}
                        variant='standard'
                        error={updatedReportName.trim() === ''}
                        helperText={updatedReportName.trim() === '' && 'report name cannot be empty'}
                        style={{ height: '2rem' }}
                        onChange={(e) => setUpdatedReportName(e.target.value)}
                    />
                    <Grid sx={{ display: 'flex' }}>
                        <Button onClick={() => handleNameEdit()} disabled={updatedReportName.trim() === ''}>Save</Button>
                        <Button onClick={() => handleCancelEdit()}>Cancel</Button>
                    </Grid>
                </>)
                :
                (<Grid style={{ display: 'flex' }}>
                    <Typography
                        variant="h1"
                        style={{
                            minWidth: '240px',
                            font: "normal normal bold 52px/32px"
                        }}
                    >
                        {reportName}
                    </Typography>
                    <p
                        style={{
                            marginLeft: '1rem',
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            font: "normal normal normal 12px / 32px Helvetica Neue"
                        }}
                        onClick={() => setIsNameEditing(true)}
                    >
                        <EditOutlinedIcon style={{ fontSize: '1rem' }} />
                        Edit Report Name
                    </p>
                </Grid>)
            }
        </Grid>
    )
}

export default EditReportName