const fileNameAlreadyExists = (data, file_name) => {
    const alreadyExists = data?.find(file => file?.file_name === file_name)
    return alreadyExists === undefined ? false : true
}

const addTimeStamp = (fileName) => {
    const currentTime = new Date(new Date().toLocaleString('en', { timeZone: 'america/new_york' }))
    const currentTimeStamp = createTimeStamp(currentTime);

    const indexOfLastDot = fileName.lastIndexOf('.')
    const fileNameWithTimeStamp = fileName.substring(0, indexOfLastDot) + `_${currentTimeStamp}` + fileName.substring(indexOfLastDot)
    return fileNameWithTimeStamp
}


const fileValidation = (file) => {
    const fileSize = file.size
    const response = {
        isValidFile: true,
        errorMessage: null
    }
    if (fileSize <= 0) {
        response.isValidFile = false
        response.errorMessage = "File Contains No Data"
    }
    if (fileSize >= 10485760) {
        response.isValidFile = false
        response.errorMessage = "File Size Too Large (max limit is 10MB)"
    }
    return response
}



const createTimeStamp = (currentTime) => {

    let years = currentTime.getFullYear()
    let months = currentTime.getMonth() + 1
    let days = currentTime.getDate()
    let hours = currentTime.getHours()
    let minutes = currentTime.getMinutes()
    let seconds = currentTime.getSeconds()

    if (months < 10) {
        months = '0' + months
    }
    if (days < 10) {
        days = '0' + days
    }

    if (hours < 10) {
        hours = "0" + hours
    }
    if (minutes < 10) {
        minutes = "0" + minutes
    }
    if (seconds < 10) {
        seconds = "0" + seconds
    }
    let timeStamp = `${years}${months}${days}_${hours}${minutes}${seconds}`
    return timeStamp;
}


// Validating the client name
const isValidName = (name) => {
    var validPattern = /^[A-Za-z.\s]*$/
    const isValid = name.trim() !== "" && validPattern.test(name.trim())
    return isValid
}

const isClientAlreadyExist = (allClients, newClientName) => {
    const clientExist = allClients?.find(client => client?.data?.client_name?.toLowerCase().trim() === newClientName.toLowerCase().trim())
    return clientExist ? true : false
}


// sort array based on timeStamp of key 
/**
 * @param {array} arr: array which needs to be sorted
 * @param {string} key: key which has the file name with time stamp.
 * @return {array} returns sorted array
 */

const sortByTimeStamp = (arr, key) => {
    const arrCopy = [...arr]
    // eslint-disable-next-line no-unused-vars
    const sample_time_stamp = `20221214_093358`
    const key_exist_in_object = arr[0]?.hasOwnProperty(key)

    arrCopy.sort((a, b) => {
        let file_a_name;
        let file_b_name;

        if (key_exist_in_object) {
            file_a_name = a[key]
            file_b_name = b[key]
        } else {
            file_a_name = a.data[key]
            file_b_name = b.data[key]
        }

        // last underscore of file name is always the underscore used in time stamp.
        const time_stamp_a_underscore_index = file_a_name.lastIndexOf('_')
        const time_stamp_b_underscore_index = file_b_name.lastIndexOf('_')


        // slicing the time stamp from file name, 
        // (subtracting 8 and adding 7 because there are 8 chars before underscore and 6 chars after underscore of timestamp)
        const file_a_time_stamp = file_a_name.slice(time_stamp_a_underscore_index - 8, time_stamp_a_underscore_index + 7)
        const file_b_time_stamp = file_b_name.slice(time_stamp_b_underscore_index - 8, time_stamp_b_underscore_index + 7)

        if (file_a_time_stamp > file_b_time_stamp) return 1
        if (file_a_time_stamp < file_b_time_stamp) return -1
        return 0
    })

    return arrCopy
}


export {
    fileNameAlreadyExists,
    addTimeStamp,
    fileValidation,
    createTimeStamp,
    isValidName,
    isClientAlreadyExist,
    sortByTimeStamp
}